// Constants
export enum EmailLabel {
  INBOX = "INBOX",
  SENT = "SENT",
  DRAFT = "DRAFT",
  SPAM = "SPAM",
  TRASH = "TRASH",
  UNREAD = "UNREAD",
  STARRED = "STARRED",
  IMPORTANT = "IMPORTANT",
  PERSONAL = "CATEGORY_PERSONAL",
  SOCIAL = "CATEGORY_SOCIAL",
  PROMOTIONS = "CATEGORY_PROMOTIONS",
  UPDATES = "CATEGORY_UPDATES",
  FORUMS = "CATEOGRY_FORUMS",
  CHAT = "CHAT",
}

export enum ECategories {
  SOCIAL = "CATEGORY_SOCIAL",
  UPDATES = "CATEGORY_UPDATES",
  FORUMS = "CATEGORY_FORUMS",
  PROMOTIONS = "CATEGORY_PROMOTIONS",
  PERSONAL = "CATEGORY_PERSONAL",
}
