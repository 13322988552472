import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
// import { useMailboxLabels } from "../../hooks/queries/useMailboxLabels";
import LabelAutoComplete from "../ui/labelAutoComplete";
import { useMailboxLabels } from "../../hooks/queries/useMailboxLabels";
import { useLabel } from "../../hooks/mutations/useLabel";
import {
  useSenders,
  useSendersWithMessagesSelected,
} from "../../store/selectors";
import { usePrevious } from "../../hooks/common/usePrevious";

interface ILabelModalProps {
  open: boolean;
  onClose: () => void;
}

export const LabelModal = ({ open, onClose }: ILabelModalProps) => {
  const senders = useSenders();
  const sendersWithMessagesSelected = useSendersWithMessagesSelected();
  const { label: applyLabels, isLabelLoading } = useLabel();
  const { labels } = useMailboxLabels();

  const previousLabelLoadingState = usePrevious(isLabelLoading);

  const labelInputRef = useRef(null);
  const [labelsToAdd, setLabelsToAdd] = useState<string[]>([]);

  const handleSelectOption = (optLabel: string) => {
    setLabelsToAdd((prev: string[]) => [...(prev ?? []), optLabel]);
  };

  const handleRemoveOption = (optLabel: string) => {
    setLabelsToAdd((prev: string[]) =>
      prev.filter((label: string) => label !== optLabel)
    );
  };

  const handleConfirm = () => {
    const labelIdsToAdd = labelsToAdd.map((label: string) => {
      const labelId = labels?.find((l) => l.name === label)?.id!;
      return labelId;
    });
    applyLabels({
      labelsToAdd: labelIdsToAdd,
      sendersWithMessagesSelected,
      senders,
    });
  };

  useEffect(() => {
    // when the label is not loading after confirm is clicked, close the modal.
    if (!isLabelLoading && previousLabelLoadingState) {
      onClose();
    }
  }, [isLabelLoading, previousLabelLoadingState, onClose]);

  return (
    <Modal
      size={{ base: "full", md: "lg" }}
      isOpen={open}
      onClose={onClose}
      initialFocusRef={labelInputRef}
      isCentered
    >
      <ModalOverlay bg="rgba(0,0,0,.8)" />
      <ModalContent h="60vh">
        <ModalCloseButton />
        <ModalHeader>Apply Labels</ModalHeader>
        <ModalBody px={{ base: 5 }}>
          <LabelAutoComplete
            labelsToAdd={labelsToAdd}
            handleOptionSelect={handleSelectOption}
            handleRemoveOption={handleRemoveOption}
            labelInputRef={labelInputRef}
          />
        </ModalBody>
        <ModalFooter mt={100}>
          <ButtonGroup spacing={2}>
            <Button onClick={onClose} tabIndex={1} variant="secondary">
              Cancel
            </Button>
            <Button
              isLoading={isLabelLoading}
              onClick={handleConfirm}
              tabIndex={0}
            >
              Confirm
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
