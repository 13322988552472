import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import routes, { LoginRouter, RequireAuth } from "./router";
import { useAuth } from "./context/AuthContext";
import { Theme } from "./theme/theme";
import { ChakraProvider, Flex } from "@chakra-ui/react";
import { SideNav } from "./components/sideNav";

function App() {
  const { user, loading } = useAuth();

  if (loading || user === null) {
    return <div>Loading...</div>;
  }

  return (
    <ChakraProvider theme={Theme}>
      <Router>
        <Flex h={window.innerHeight} overflowY="hidden">
          {user && <SideNav />}
          <Routes>
            {routes.map((route) => {
              if (route.requireAuth) {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <RequireAuth user={user}>{route.element}</RequireAuth>
                    }
                  />
                );
              }
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <LoginRouter user={user}>{route.element}</LoginRouter>
                  }
                />
              );
            })}
          </Routes>
        </Flex>
      </Router>
    </ChakraProvider>
  );
}

export default App;
