import { extendTheme } from "@chakra-ui/react";
import { menuTheme } from "./menuTheme";

const isMobile = window.innerWidth < 768;

export const Theme = extendTheme({
  fonts: {
    heading: `Feather, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `Feather, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    mono: `HKGroteskPro, SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
  },
  styles: {
    global: {
      body: {
        bg: isMobile ? "gray.900" : "gray.800",
        color: "whiteAlpha.900",
        "&::-webkit-scrollbar": { width: "3px" },
        "&::-webkit-scrollbar-track": { background: "gray.700" },
        "&::-webkit-scrollbar-thumb": { background: "gray.400" },
      },
    },
  },
  colors: {
    primary: {
      50: "#e2dbff",
      100: "#cac7ed",
      200: "#a7a2dd",
      300: "#847cce",
      400: "#6257c0",
      500: "#493da6",
      600: "#383082",
      700: "#28225d",
      800: "#17143a",
      900: "#080619",
    },
    secondary: {
      50: "#717eff2b",
      100: "#b8c8fd",
      200: "#89a4f6",
      300: "#5c7fef",
      400: "#2f5bea",
      500: "#1541d0",
      600: "#0e33a3",
      700: "#072476",
      800: "#011649",
      900: "#00071e",
    },
  },
  components: {
    Menu: menuTheme,
    Text: {
      variants: {
        secondary: { color: "whiteAlpha.700" },
      },
    },
    Input: {
      baseStyle: {
        bg: "blackAlpha.700",
      },
      variants: {
        filled: {
          bg: "blackAlpha.700",
        },
      },
    },
    Button: {
      variants: {
        outline: {
          borderColor: "primary.500",
          color: "white",
          _hover: {
            bg: "primary.500",
          },
        },
        ghost: {
          _hover: {
            bg: "primary.500",
          },
        },
        inactive: {
          bg: "primary.500",
          opacity: 0.5,
          boxShadow: "none",
        },
        solid: {
          color: "white",
          bg: "primary.500",
          _hover: { bg: "primary.400" },
        },
        pill: {
          borderRadius: "full",
          borderColor: "primary.500",
          borderWidth: "1px",
          bg: "secondary.400",
          color: "gray.300",
          boxShadow: "2xl",
          fontSize: "sm",
          zIndex: 30,
        },
      },
    },
    Drawer: {
      baseStyle: {
        body: {
          background: "gray.800",
        },
        header: {
          background: "gray.800",
        },
        footer: {
          background: "gray.800",
        },
      },
      variants: {
        solid: {
          body: {
            bg: "primary.800",
          },
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          background: "gray.800",
        },
      },
      variants: {
        solid: {
          body: {
            bg: "primary.800",
          },
        },
      },
    },
    Skeleton: {
      baseStyle: {
        startColor: "gray.700",
        endColor: "gray.800",
      },
    },
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
});
