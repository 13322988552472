import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Image,
  VStack,
  HStack,
  ResponsiveValue,
  Flex,
} from "@chakra-ui/react";
// @ts-ignore
import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

// @ts-ignore
import mixpanel from "mixpanel-browser";
import { Blur } from "../components/ui/blur";
import { useAppDispatch, useAppSelector } from "../store/provider";
import { setLoadingFlowCompleted } from "../store/slices/loadingSlice";
import { useQueryClient } from "@tanstack/react-query";
import { EQueryKeys } from "../lib/queryKeys";
import { User } from "firebase/auth";
import { useAuth } from "../context/AuthContext";
const screenHeight = window.innerHeight;

export function Intro() {
  const navigate = useNavigate();
  const flexStyles = {
    pos: "relative" as ResponsiveValue<"relative">,
    flex: 1,
    direction: "column" as ResponsiveValue<"column">,
    alignItems: "center",
    //   backgroundImage: `url(${process.env.PUBLIC_URL}/images/lessmail-loading-bg.svg)`,
    //   backgroundSize: "cover",
  };

  const headingStyles = {
    mt: { base: 10, md: 20 },
    fontSize: { base: "3xl", md: "6xl" },
    textAlign: "center" as ResponsiveValue<"center">,
  };

  const textStyle = {
    w: { base: "90%", md: "80%", lg: "50%" },
    textAlign: "center" as ResponsiveValue<"center">,
    mt: 4,
    fontSize: { base: "md", md: "xl" },
  };

  const handleClick = () => {
    mixpanel.track("entered_loading_flow");
    navigate("/loading");
  };

  return (
    <Flex h={{ base: "100vh" }} p={8} {...flexStyles}>
      <Box mt={{ base: 10, md: 100 }} />
      <NewLogo />
      <Heading {...headingStyles}>Welcome to Lessmail!</Heading>
      <Text {...textStyle}>
        While we securely load your inbox let’s take a quick look at how you can
        use Lessmail to get and keep your inbox clean. Leave this tab open until
        your mailbox is loaded to avoid issues.
      </Text>
      <Button
        rightIcon={<FaChevronRight />}
        size="lg"
        w={{
          base: "full",
          md: "xl",
        }}
        mt="auto"
        onClick={handleClick}
      >
        How to use Lessmail
      </Button>
    </Flex>
  );
}

export const NewLogo = () => {
  return (
    <svg
      width="97"
      height="97"
      viewBox="0 0 194 193"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.34164 8.68328C-0.653065 4.69387 2.24791 0 6.7082 0H187.292C191.752 0 194.653 4.69388 192.658 8.68328L102.367 189.267C100.155 193.689 93.8446 193.689 91.6334 189.267L1.34164 8.68328Z"
        fill="#553DE7"
      />
    </svg>
  );
};

const images = [
  {
    src: `${process.env.PUBLIC_URL}/gifs/mailing-list.gif`,
    heading: "Unsubscribe in Bulk",
    subheading: `Use the "Mailing Lists" tab located on the sidebar to unsubscribe from mailing lists in bulk!`,
  },
  {
    src: `${process.env.PUBLIC_URL}/gifs/find-all.gif`,
    heading: 'Easily "Find all"',
    subheading: `Many mailing lists will send from multiple addresses, if there's a lot of emails from similar senders and you want to trash or unsubscribe from all... Right-click or long-press and click "find all" or "select all".`,
  },
  {
    src: `${process.env.PUBLIC_URL}/gifs/search.gif`,
    heading: "Seamlessly Search",
    subheading:
      "Search for multple senders in one go and quickly apply bulk actions to targeted groups of your mailbox.",
  },
  {
    src: `${process.env.PUBLIC_URL}/gifs/categories.gif`,
    heading: "Group by Category",
    subheading: `Use the "Categories" tab to group senders and apply bulk actions for faster cleaning.`,
  },
  {
    src: `${process.env.PUBLIC_URL}/gifs/label.gif`,
    heading: "Label and Organize quickly",
    subheading: `Create labels, and apply them to multiple senders at once`,
  },
];

const LoadingScreen = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const globalLoadingState = useAppSelector((state) => state.loading);

  // State to keep track of the current index
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to go to the next image
  const nextImage = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      if (!globalLoadingState.mailboxLoading) {
        mixpanel.track("finished_loading_flow");
        dispatch(setLoadingFlowCompleted());
        queryClient.setQueryData(
          [EQueryKeys.LoadingFlowCompelted, (user as User).uid],
          true
        );
      }
    }
  };

  const prevImage = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const currentItem = images[currentIndex];

  return (
    <Flex
      flexDir={{ base: "column" }}
      alignItems={{
        base: "center",
        md: "center",
      }}
      p={6}
      pt={12}
    >
      <Flex
        flexDirection="column"
        w="full"
        pos="fixed"
        h={12}
        top={0}
        flex={1}
        p={3}
        bg="gray.900"
      >
        <Text variant="secondary">
          mailbox {globalLoadingState.progress}% loaded
        </Text>
      </Flex>
      <Blur
        opacity={0.8}
        zIndex={0}
        position={"absolute"}
        top={{ base: "unset", md: -10 }}
        bottom={{ base: -100, md: "unset" }}
        left={-10}
        style={{ filter: "blur(70px)" }}
      />

      <VStack textAlign="center">
        <Heading alignSelf="center" as="h3" size="xl">
          {currentItem.heading}
        </Heading>
        <Text fontSize="sm" mt={2}>
          {currentItem.subheading}
        </Text>
      </VStack>
      <Box
        zIndex={10}
        alignSelf={{ base: "center", md: "unset" }}
        h={screenHeight * 0.75}
        // w={{ sm: "100%", md: "50%" }}
        p={4}
        overflow="hidden"
      >
        {/* Image will be displayed here */}
        <Image h="full" src={currentItem.src} alt={currentItem.heading} />
      </Box>
      <HStack
        zIndex="20"
        pos="fixed"
        bottom="0"
        p={4}
        bg="gray.900"
        display={{ base: "flex" }}
        w="full"
        justifyContent="flex-end"
      >
        {currentIndex !== 0 && (
          <Button onClick={prevImage} disabled={currentIndex === 0}>
            Back
          </Button>
        )}
        <Button
          isLoading={
            currentIndex === images.length - 1 &&
            globalLoadingState.mailboxLoading
          }
          loadingText="Loading your inbox..."
          onClick={nextImage}
        >
          {currentIndex === images.length - 1 ? "Continue to Lessmail" : "Next"}
        </Button>
      </HStack>
    </Flex>
  );
};
export default LoadingScreen;
