import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ILoadingState {
  mailboxLoading: boolean;
  mailboxSyncing: boolean;
  progress: number;
  loadingFlowCompleted: boolean;
}

const initialState: ILoadingState = {
  mailboxLoading: true,
  mailboxSyncing: false,
  progress: 0,
  loadingFlowCompleted: false,
};

const loadingSlice = createSlice({
  name: "actions",
  initialState,
  reducers: {
    setMailboxLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        mailboxLoading: action.payload,
      };
    },
    setMailboxSyncing: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        mailboxSyncing: action.payload,
      };
    },
    setProgress: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        progress: action.payload,
      };
    },
    setLoadingFlowCompleted: (state) => {
      return {
        ...state,
        loadingFlowCompleted: true,
      };
    },
  },
});

export const {
  setMailboxLoading,
  setMailboxSyncing,
  setProgress,
  setLoadingFlowCompleted,
} = loadingSlice.actions;

export default loadingSlice.reducer;
