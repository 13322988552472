import {
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SimpleGrid,
} from "@chakra-ui/react";
import Fuse from "fuse.js";
import { useRef, useState } from "react";
import { useHistory } from "../hooks/queries/useGetHistory";
import { HistoryCard } from "../components/historyCard";

export default function History() {
  const { history } = useHistory();
  console.log({ history });
  return (
    <Flex p={8} direction="column" flex={1} overflowY="scroll">
      <SimpleGrid spacing={2} columns={{ base: 1, md: 2, lg: 3, xl: 4 }}>
        {history?.map((h) => (
          <HistoryCard key={h.id} {...h} />
        ))}
      </SimpleGrid>
    </Flex>
  );
}
