import {
  format,
  formatDistance,
  isToday,
  isYesterday,
  isWithinInterval,
  subWeeks,
} from "date-fns";

export function formatLastSendDate(lastSendDate?: number | string): string {
  // console.log({ lastSendDate });
  const date = new Date(Number(lastSendDate));
  const dateIsWithinWeek = isWithinInterval(date, {
    start: subWeeks(new Date(), 1),
    end: new Date(),
  });

  let formattedDate;
  if (isToday(date)) {
    formattedDate = format(date, "h:mm a");
  } else if (isYesterday(date)) {
    formattedDate = "Yesterday";
  } else if (dateIsWithinWeek) {
    formattedDate = formatDistance(date, new Date(), {
      addSuffix: true,
    });
  } else {
    formattedDate = format(date, "MMM d");
  }

  return formattedDate;
}
