import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum ESortOptions {
  MessageNum = "Number of Messages (High to Low)",
  Latest = "Date (Newest to Top)",
  Oldest = "Date (Oldest to Top)",
}

const initialState: ESortOptions = ESortOptions.MessageNum;
const sortSlice = createSlice({
  name: "sortType",
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<ESortOptions>) => {
      return action.payload as any;
    },
  },
});
export const { setSort } = sortSlice.actions;

export default sortSlice.reducer;
