import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../../context/AuthContext";
import { EQueryKeys } from "../../lib/queryKeys";
import { postCreateLabel } from "../../api/api";
import { IClientUser } from "../../api/types";

export const useCreateLabel = () => {
  const queryClient = useQueryClient();
  const { user, oauthToken, appCheckToken } = useAuth();
  const authObject = {
    ...user,
    oauthToken,
    appCheckToken,
  };

  const { mutateAsync: createLabel, isPending: isCreateLabelLoading } =
    useMutation({
      mutationKey: [EQueryKeys.CreateLabel],
      mutationFn: (labelName: string) =>
        postCreateLabel({ auth: authObject as IClientUser, labelName }),
      onError: (error) => {
        alert("Error creating label: " + error.toString());
      },
      onSuccess: () => {
        queryClient.refetchQueries({
          queryKey: [EQueryKeys.Labels, (user as any)?.uid],
        });
      },
    });

  return { createLabel, isCreateLabelLoading };
};
