import {
  Box,
  Flex,
  FormControl,
  FormHelperText,
  InputGroup,
  Text,
} from "@chakra-ui/react";
import * as React from "react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteTag,
} from "@choc-ui/chakra-autocomplete";
import { EmailLabel } from "../../lib/constants";
import { useMailboxLabels } from "../../hooks/queries/useMailboxLabels";
import { scrollbarDisplayNone } from "../toolbar/toolbar";
import { Schema$Label } from "@/functions/types";

interface ILabelAutoCompleteProps {
  labelInputRef: React.RefObject<HTMLInputElement>;
  labelsToAdd: string[];
  handleOptionSelect: (optLabel: string) => void;
  handleRemoveOption: (optLabel: string) => void;
}

function LabelAutoComplete({
  labelInputRef,
  labelsToAdd,
  handleOptionSelect,
  handleRemoveOption,
}: ILabelAutoCompleteProps) {
  // Hooks
  const { labels } = useMailboxLabels();

  // State
  const [inputValue, setInputValue] = React.useState("");

  const options = labels?.reduce((acc: any[], label: Schema$Label) => {
    const exludedLabels = [
      EmailLabel.DRAFT,
      EmailLabel.SENT,
      EmailLabel.TRASH,
      EmailLabel.CHAT,
      EmailLabel.SPAM,
      EmailLabel.STARRED,
      EmailLabel.IMPORTANT,
      EmailLabel.INBOX,
      EmailLabel.UNREAD,
    ];

    if (exludedLabels.includes(label.id as EmailLabel)) return acc;

    if (label.id?.startsWith("CATEGORY_")) return acc;

    if (labelsToAdd?.includes(label.name as string)) return acc;

    return [
      ...acc,
      {
        name: label.name,
        value: label.name,
        id: label.id,
        color: label.color ?? "gray.300",
      },
    ];
  }, []);

  const handleInputChange = (newVal: any) => {
    setInputValue(newVal);
  };

  const handleSelectOption = (val: any) => {
    handleOptionSelect(val);
    setInputValue("");
  };

  const handleTagRemove = (value: string) => {
    handleRemoveOption(value);
  };

  return (
    <FormControl w="100%">
      <Box borderRadius="lg" bg="gray.700">
        <AutoComplete
          value={labelsToAdd}
          onSelectOption={(item) => handleSelectOption(item?.item.value)}
          multiple
          openOnFocus
          selectOnFocus={false}
          freeSolo
        >
          <Box bg="unset">
            <InputGroup p={0} _focus={{ bg: "unset" }}>
              <AutoCompleteInput
                onChange={(e) => handleInputChange(e.target.value)}
                value={inputValue}
                ref={labelInputRef}
                variant="filled"
                placeholder="Select one or more labels"
                bg={"gray.700"}
              >
                {({ tags }) =>
                  tags.map((tag, index) => (
                    <AutoCompleteTag
                      color="white"
                      bg="gray.800"
                      key={index}
                      label={tag.label}
                      onRemove={() => handleTagRemove(tag.label)}
                    />
                  ))
                }
              </AutoCompleteInput>
            </InputGroup>
            <AutoCompleteList
              boxShadow="none"
              mt={0}
              w="100%"
              css={scrollbarDisplayNone}
            >
              {options?.map((opt, index) => (
                <AutoCompleteItem
                  mb={2}
                  key={`option-${index}`}
                  value={opt}
                  _focus={{ bg: "rgba(0,0,0,.1)" }}
                  _first={{
                    _focus: { bg: "unset !important" },
                  }}
                  textTransform="capitalize"
                  flex={1}
                >
                  <Flex>
                    <Text>{opt.name}</Text>
                  </Flex>
                </AutoCompleteItem>
              ))}
            </AutoCompleteList>
          </Box>
        </AutoComplete>
      </Box>
      <FormHelperText>
        Choose one or more labels to apply to the selection.
      </FormHelperText>
    </FormControl>
  );
}

export default LabelAutoComplete;
