import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISender } from "./senderSlice";

export interface ILabelFilter {
  labelId: string;
  and: string[];
  labelName: string;
}

export enum EMailBoxFilters {
  INBOX = "INBOX",
  // SENT = "SENT",
  // DRAFT = "DRAFT",
  // TRASH = "TRASH",
  // SPAM = "SPAM",
  STARRED = "STARRED",
  ALL = "ALL",
  LISTS = "Mailing Lists",
  IMPORTANT = "IMPORTANT",
}

export enum EFilterTypes {
  LabelFilter = "LABEL",
  UnsubscribeLink = "UNSUBSCRIBE_LINK",
}

interface FilterState {
  labelFilter: ILabelFilter;
  search: string;
  filteredSenders: ISender[];
}

const initialState: FilterState = {
  labelFilter: {
    labelId: EMailBoxFilters.INBOX,
    labelName: EMailBoxFilters.INBOX,
    and: [],
  },
  search: "",
  filteredSenders: [],
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    updateLabelFilter: (
      state,
      action: PayloadAction<FilterState["labelFilter"]>
    ) => {
      state.labelFilter = action.payload;
    },
    updateSearchFilter: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    clearSearch: (state) => {
      state.search = "";
      // Additional logic to reset the filtered senders based on the cleared search
    },
  },
});

export const { updateLabelFilter, updateSearchFilter, clearSearch } =
  filterSlice.actions;
export default filterSlice.reducer;
