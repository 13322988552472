import { useInfiniteQuery } from "@tanstack/react-query";
import { useAuth } from "../../context/AuthContext";
import { useAppDispatch } from "../../store/provider";
import { getHistory } from "../../api/api";
import { IClientUser } from "../../api/types";
import { User } from "@sentry/react";
import { EQueryKeys } from "../../lib/queryKeys";
import { useEffect, useState } from "react";
import { IClientHistoryDoc } from "../../../../functions/types";

export const useHistory = () => {
  const [history, setHistory] = useState<IClientHistoryDoc[]>([]);

  const dispatch = useAppDispatch();
  const { user, oauthToken, appCheckToken } = useAuth();
  const authObject = {
    ...user,
    oauthToken,
    appCheckToken,
  };

  const {
    data: pageData,
    fetchNextPage,
    isFetching: isFetchingNextPage,
    isFetched,
    isLoading,
    hasNextPage,
  } = useInfiniteQuery({
    enabled: !!authObject,
    queryKey: [EQueryKeys.History, (user as User).uid],
    queryFn: (data) =>
      getHistory({
        auth: authObject as IClientUser,
        pageParam: data?.pageParam,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => lastPage.next,
  });

  const fetchMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    const history =
      pageData?.pages?.reduce((acc: IClientHistoryDoc[], page) => {
        return [...acc, ...page.history];
      }, []) ?? [];
    setHistory(history);
  }, [dispatch, pageData]);

  return {
    history,
    fetchNextPage: fetchMore,
    isLoading,
    isFetchingNextPage: isFetched && isFetchingNextPage && !isLoading, // isFetching is true when the query is fetching the next page or the query is fetched for the first time. Is loading is only true when the query is fetching the first page.
  };
};
