import { Image, ImageProps } from "@chakra-ui/react";
import React, { memo } from "react";

interface SenderAvatarProps extends ImageProps {
  urlEnding: string;
  senderName: string;
  small?: boolean;
}

const SenderAvatar = memo(
  ({ senderName, urlEnding, small, ...other }: SenderAvatarProps) => {
    return (
      <Image
        mr={4}
        w={small ? "32px" : "42px"}
        h={small ? "32px" : "42px"}
        rounded="full"
        objectFit="cover"
        src={`https://logo.clearbit.com/${urlEnding}`}
        fallbackSrc={`https://ui-avatars.com/api/?name=${senderName}&background=553DE7&color=fff`}
        alt={urlEnding}
        {...other}
      />
    );
  },
  (prev, next) => {
    return prev.urlEnding === next.urlEnding;
  }
);

export default SenderAvatar;
