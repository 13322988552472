import { Flex, Heading, Text, Tooltip, Box } from "@chakra-ui/react";
import { BsMegaphoneFill } from "react-icons/bs";
import { ISender } from "../../store/slices/senderSlice";

export const CardBody = ({ sender }: { sender: ISender }) => {
  const email = sender.email?.split("@")[0];
  const at = sender.email?.split("@")[1];

  return (
    <Flex overflow="hidden" direction="column" flex={1}>
      <Flex align="center">
        {sender.unsubscribeLink && (
          <Tooltip bg="gray.900" label="Mailing List">
            <Box color="gray.300" mr={2}>
              <BsMegaphoneFill size={18} />
            </Box>
          </Tooltip>
        )}

        <Heading
          className="sender-card-text"
          noOfLines={1}
          as="h3"
          size={{ base: "sm", md: "sm" }}
          mb={0}
        >
          {sender.name}
        </Heading>
      </Flex>

      {sender.name !== sender.email && (
        <Flex>
          <Text
            className="sender-card-text"
            color="gray.300"
            noOfLines={1}
            maxW="180px"
            fontSize={{ base: "md", md: "sm" }}
            my={0}
          >
            {email}
          </Text>
          <Text
            className="sender-card-text"
            color="gray.300"
            textOverflow="ellipses"
            noOfLines={1}
            fontSize={{ base: "md", md: "sm" }}
            my={0}
          >
            @{at}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
