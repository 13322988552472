import { domain } from "../environment";
import { IClientUser } from "./types";
import {
  IClientHistoryDoc,
  IGetSendersResponse,
  ISyncMailboxResponse,
  Schema$Filter,
  Schema$Label,
} from "@/functions/types";

export const getSenders = async ({
  auth,
  pageParam,
}: {
  auth: IClientUser;
  pageParam: string | null;
}): Promise<IGetSendersResponse> => {
  const params = new URLSearchParams();
  if (pageParam) params.append("startAt", pageParam);
  const response = await fetch(`${domain}/bulk/senders?${params.toString()}`, {
    method: "GET",
    headers: getHeaders(auth),
  });
  const body = await response.json();
  //console.log({ getGmailSendersResult: body });
  if (!response.ok) throw body.errors ? body.errors : body;

  return body;
};

export const sync = async ({
  auth,
  historyId,
}: {
  auth: IClientUser;
  historyId: string;
}): Promise<ISyncMailboxResponse> => {
  const response = await fetch(`${domain}/bulk/senders/sync/${historyId}`, {
    method: "GET",
    headers: getHeaders(auth),
  });
  const body = await response.json();
  console.log({ syncResult: body });
  if (!response.ok) throw body.errors ? body.errors : body;

  return body;
};

export const getMessagesFromSender = async ({
  auth,
  email,
  labelId,
  pageParam,
}: {
  auth: IClientUser;
  email: string;
  labelId?: string;
  pageParam: string | null;
}) => {
  const params = new URLSearchParams();
  if (labelId) params.append("labelId", labelId);
  if (pageParam) params.append("startAt", pageParam);
  const response = await fetch(
    `${domain}/bulk/senders/${email}/messages?${params.toString()}`,
    {
      method: "GET",
      headers: getHeaders(auth),
    }
  );
  const body = await response.json();
  //console.log({ getMessagesFromSenderResult: body });
  if (!response.ok) throw body.errors ? body.errors : body;

  return body;
};

export const getLabels = async ({
  auth,
}: {
  auth: IClientUser;
}): Promise<Schema$Label[]> => {
  const response = await fetch(`${domain}/api/user/labels`, {
    method: "GET",
    headers: getHeaders(auth),
  });
  const body = await response.json();
  //console.log({ getLabelResult: body });
  if (!response.ok) throw body.errors ? body.errors : body;

  return body;
};

export const getFilters = async ({
  auth,
}: {
  auth: IClientUser;
}): Promise<Schema$Filter[]> => {
  const response = await fetch(`${domain}/api/user/filters`, {
    method: "GET",
    headers: getHeaders(auth),
  });
  const body = await response.json();
  //console.log({ getFiltersResult: body });
  if (!response.ok) throw body.errors ? body.errors : body;

  return body;
};

export const getHistory = async ({
  auth,
  pageParam,
}: {
  auth: IClientUser;
  pageParam: number | null;
}) => {
  const params = new URLSearchParams();
  if (pageParam) params.append("startAt", pageParam.toString());
  const response = await fetch(
    `${domain}/api/user/history?${params.toString()}`,
    {
      method: "GET",
      headers: getHeaders(auth),
    }
  );
  const body = await response.json();
  //console.log({ getHistoryResult: body });
  if (!response.ok) throw body.errors ? body.errors : body;

  return body as {
    history: IClientHistoryDoc[];
    next?: number; // firebase doc timestamp
  };
};

export const postArchive = async ({
  auth,
  messageGroups,
}: {
  auth: IClientUser;
  messageGroups: {
    [email: string]: string[];
  };
}) => {
  const response = await fetch(`${domain}/api/actions/archive`, {
    method: "POST",
    headers: getHeaders(auth),
    body: JSON.stringify({ messageGroups }),
  });
  const body = await response.json();
  //console.log({ postArchiveResult: body });
  if (!response.ok) throw body.errors ? body.errors : body;

  return body;
};

export const postTrash = async ({
  auth,
  messageGroups,
}: {
  auth: IClientUser;
  messageGroups: {
    [email: string]: {
      messageIds: string[];
      selectedLabels: string[];
    };
  };
}) => {
  console.log({ messageGroups });
  const response = await fetch(`${domain}/api/actions/trash`, {
    method: "POST",
    headers: getHeaders(auth),
    body: JSON.stringify({ messageGroups }),
  });
  const body = await response.json();
  console.log({ postTrashResult: body });
  if (!response.ok) throw body.errors ? body.errors : body;

  return body;
};

export const postUnsubscribe = async ({
  auth,
  emails,
}: {
  auth: IClientUser;
  emails: string[];
}) => {
  const response = await fetch(`${domain}/api/actions/unsubscribe`, {
    method: "POST",
    headers: getHeaders(auth),
    body: JSON.stringify({ emails }),
  });
  const body = await response.json();
  console.log({ postUnsubscribeResult: body });
  if (!response.ok) throw body.errors ? body.errors : body;

  return body;
};

export const postModifyLabels = async ({
  auth,
  messageGroups,
  labelsToAdd,
}: {
  auth: IClientUser;
  messageGroups: {
    [email: string]: {
      messageIds: string[];
      selectedLabels: string[];
    };
  };
  labelsToAdd: string[];
}) => {
  const response = await fetch(`${domain}/api/actions/label`, {
    method: "POST",
    headers: getHeaders(auth),
    body: JSON.stringify({ messageGroups, labelsToAdd }),
  });
  const body = await response.json();
  console.log({ postModifyLabelsResult: body });
  if (!response.ok) throw body.errors ? body.errors : body;

  return body;
};

export const postCreateLabel = async ({
  auth,
  labelName,
}: {
  auth: IClientUser;
  labelName: string;
}) => {
  const response = await fetch(`${domain}/api/actions/create-label`, {
    method: "POST",
    headers: getHeaders(auth),
    body: JSON.stringify({ labelName }),
  });
  const body = await response.json();
  console.log({ postCreateLabelResult: body });
  if (!response.ok) throw body.errors ? body.errors : body;

  return body;
};

export const undo = async ({
  auth,
  historyId,
}: {
  auth: IClientUser;
  historyId: string;
}) => {
  const response = await fetch(`${domain}/api/actions/undo`, {
    method: "POST",
    headers: getHeaders(auth),
    body: JSON.stringify({ historyId }),
  });
  const body = await response.json();
  console.log({ undoResult: body });
  if (!response.ok) throw body.errors ? body.errors : body;

  return body;
};

export function getHeaders(user: IClientUser) {
  let headers: any = {
    "Content-Type": "application/json",
  };
  if (user.oauthToken && user.appCheckToken && user.accessToken) {
    headers["Authorization"] = `Bearer ${user.oauthToken}`;
    headers["x-app-check-token"] = user.appCheckToken;
    headers["x-firebase-id-token"] = user.accessToken;
  }
  return headers;
}
