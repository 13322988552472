/* eslint-disable react/display-name */
import React from "react";
import { Divider, Flex, Tag, Text, Tooltip, theme } from "@chakra-ui/react";
import { useMemo } from "react";
import { FiTag } from "react-icons/fi";
import { AiOutlineCheck } from "react-icons/ai";
import { useMessageView } from "../../context/MessageViewContext";
import { getEmailEnding } from "../../utilities/helpers";
import SenderAvatar from "../ui/senderAvatar";
import { useSelectedMessageCount } from "../../store/selectors";
import { formatLastSendDate } from "../../utilities/date";
import { useSelection } from "../../hooks/store/useSelection";
import { Schema$Label } from "@/functions/types";

export const MessageItem = ({
  message,
  labels,
}: {
  message: any;
  labels: Schema$Label[];
}) => {
  const isMobile = window.innerWidth < 768;
  const { date, labelIds, snippet, subject } = message;
  const { focusedSender } = useMessageView();
  const { toggleMessageSelection } = useSelection();
  const selectedMessageCount = useSelectedMessageCount();
  const isSelectionViewActive = selectedMessageCount > 0;

  // State
  const [isHovered, setIsHovered] = React.useState(false);

  const formattedDate = formatLastSendDate(date);

  const labelNames = useMemo(() => {
    const messageLabels = labelIds?.map((labelId: string) =>
      labels?.find((label) => label.id === labelId)
    );
    return messageLabels
      ?.map(
        (label: Schema$Label) =>
          label?.name?.split("_")[1]?.toLowerCase() || label?.name
      )
      .sort((a: string, b: string) => {
        if (a === "UNREAD") return 1;
        if (b === "UNREAD") return -1;
        else return 1;
      });
  }, [labels, labelIds]);

  const bg = useMemo(() => {
    if (message.selected) return "rgba(73,61,166,.2)";
    return "blackAlpha.100";
  }, [message]);

  return (
    <Flex
      mb={3}
      boxShadow="md"
      bg={bg}
      w="full"
      p={4}
      direction="column"
      rounded="lg"
      key={message.id}
      borderWidth={message.selected ? 2 : 0.3}
      borderColor={message.selected ? "primary.500" : "whiteAlpha.400"}
      _hover={{
        cursor: "pointer",
      }}
      onMouseEnter={() => !isMobile && setIsHovered(true)}
      onMouseLeave={() => !isMobile && setIsHovered(false)}
      onClick={() => toggleMessageSelection(message.id)}
    >
      <Flex flex={1} justify="space-between" align="center">
        {!isHovered &&
          !message.selected &&
          !isSelectionViewActive &&
          focusedSender && (
            <SenderAvatar
              senderName={message.from}
              urlEnding={getEmailEnding(focusedSender)}
              alt={`${message.from}`}
            />
          )}

        {(isHovered || message.selected || isSelectionViewActive) && (
          <>
            {!isMobile && (
              <Tooltip bg="gray.900" label="Click to select">
                <Flex
                  align="center"
                  justify="center"
                  bg={message.selected ? "secondary.500" : "none"}
                  mr={{ base: 0, md: 4 }}
                  borderRadius="full"
                  h="42px"
                  w="42px"
                  borderWidth={3}
                  borderColor="whiteAlpha.600"
                >
                  {message.selected && <AiOutlineCheck />}
                </Flex>
              </Tooltip>
            )}
            {isMobile && (
              <Flex
                align="center"
                justify="center"
                bg={message.selected ? "secondary.500" : "none"}
                mr={{ base: 0, md: 4 }}
                borderRadius="full"
                h="36px"
                w="36px"
                borderWidth={3}
                borderColor="whiteAlpha.600"
              >
                {message.selected && <AiOutlineCheck />}
              </Flex>
            )}
          </>
        )}
        <Flex gap={2} direction="column" align="flex-end" maxW="70%">
          <Text fontSize="sm"> Date: {formattedDate}</Text>
          <Flex flexWrap="wrap" justify="flex-end" color="whiteAlpha.600">
            {labelNames
              ?.filter((ln: string) => ln !== "UNREAD")
              .map((label: string, index: number) => (
                <Flex key={`${label}-${index}`} mr={1} align="center">
                  <Tag bg="gray.700" pl={2} borderWidth={0}>
                    <FiTag color={theme.colors.gray["300"]} />
                    <Text color="gray.300" ml={1}>
                      {label}
                    </Text>
                    {/* <TagCloseButton /> */}
                  </Tag>
                </Flex>
              ))}
          </Flex>
        </Flex>
      </Flex>
      <Divider borderColor="whiteAlpha.300" my={3} />
      <Text mb={2}>{subject}</Text>
      <Text fontSize="sm" variant="secondary">
        {snippet?.replace(/&#39;/g, "'")}
      </Text>
    </Flex>
  );
};
