import { useEffect } from "react";

export const useFetchOnScrollToEnd = ({
  containerId,
  callback,
}: {
  containerId: string;
  callback: Function;
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onScroll = (e: any) => {
    const currentPosition = e.target.scrollTop;
    const clientHeight = e.target.clientHeight;
    const totalHeight = e.target.scrollHeight;
    const scrollBottom = currentPosition + clientHeight;

    if (totalHeight - 300 <= scrollBottom) {
      callback();
    }
  };

  useEffect(() => {
    const container = document.getElementById(containerId);
    container?.addEventListener("scroll", onScroll);
    return () => {
      container?.removeEventListener("scroll", onScroll);
    };
  }, [containerId, callback, onScroll]);
};
