import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../../context/AuthContext";
import { EQueryKeys } from "../../lib/queryKeys";
import { postUnsubscribe } from "../../api/api";
import { useAppDispatch } from "../../store/provider";
import { IClientUser } from "../../api/types";
import {
  ISender,
  setSenders,
  unsubscribeSenders,
} from "../../store/slices/senderSlice";
import { useIsSelectionViewActive } from "../../context/SelectionViewContext";

export const useUnsubscribe = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { user, oauthToken, appCheckToken } = useAuth();
  const { setIsSelectionViewActive } = useIsSelectionViewActive();
  const authObject = {
    ...user,
    oauthToken,
    appCheckToken,
  };

  const optimisticallyUpdateSenders = (emails: string[]) => {
    const previousSenders = queryClient.getQueryData<ISender[]>([
      EQueryKeys.Senders,
      (user as IClientUser)?.uid,
    ]);
    const updatedSenders = previousSenders!.map((sender) => {
      if (emails.includes(sender.email)) {
        return {
          ...sender,
          isUnsubscribed: true,
        };
      }
      return sender;
    });
    queryClient.setQueryData<ISender[]>(
      [EQueryKeys.Senders, (user as IClientUser)?.uid],
      updatedSenders
    );
    return previousSenders;
  };

  const rollbackUnsubscribe = (previousSenders: ISender[]) => {
    queryClient.setQueryData<ISender[]>(
      [EQueryKeys.Senders, (user as IClientUser)?.uid],
      previousSenders
    );
    dispatch(setSenders(previousSenders));
  };

  const { mutate: unsubscribe, isPending } = useMutation({
    mutationKey: [EQueryKeys.Unsubscribe],
    mutationFn: async (emails: string[]) =>
      postUnsubscribe({ auth: authObject as IClientUser, emails }),
    onMutate: (emails: string[]) => {
      const previousSenders = optimisticallyUpdateSenders(emails);
      dispatch(unsubscribeSenders(emails));
      return previousSenders;
    },
    onError: (error, _, context) => {
      console.log({ error, context });
      if (context) rollbackUnsubscribe(context);
    },
    onSuccess: () => {
      setIsSelectionViewActive(false);
    },
  });

  return { unsubscribe, isUnsubscribeLoading: isPending };
};
