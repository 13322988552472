import React, { createContext, useState } from "react";
import { usePrevious } from "../hooks/common/usePrevious";

type MessageContextType = {
  isMessageViewOpen: boolean;
  setMessageViewOpen: (open: boolean) => void;
  focusedSender: string | null;
  setFocusedSender: (sender: string | null) => void;
  lastFocusedSender: string | null;
};

export const MessageContext = createContext<MessageContextType>({
  isMessageViewOpen: false,
  setMessageViewOpen: () => {},
  focusedSender: null,
  setFocusedSender: () => {},
  lastFocusedSender: null,
});

export const MessageViewProvider = ({ children }: any) => {
  const [focusedSender, setFocusedSender] = useState<string | null>(null);
  const lastFocusedSender = usePrevious(focusedSender);
  const [isMessageViewOpen, setIsMessageViewOpen] = useState<boolean>(false);

  const setMessageViewOpen = (open: boolean) => {
    setIsMessageViewOpen(open);
  };

  return (
    <MessageContext.Provider
      value={{
        lastFocusedSender,
        isMessageViewOpen,
        setMessageViewOpen,
        focusedSender,
        setFocusedSender,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export const useMessageView = () => {
  if (!React.useContext(MessageContext)) {
    throw new Error("useMessageView must be used within a MessageProvider");
  }
  return React.useContext(MessageContext);
};
