import { Flex, Avatar, Box, Text, Link, Icon, Divider } from "@chakra-ui/react";
import { User } from "firebase/auth";
import { useState } from "react";
import { BiChevronDownCircle, BiLogOutCircle } from "react-icons/bi";
import { MdManageAccounts, MdManageHistory } from "react-icons/md";
import { NavItem } from "./navItem";
import { useAuth } from "../../context/AuthContext";
import { useSidenavExpanded } from "../../context/SidenavContext";

export const UserInfo = () => {
  const { setSidenavExpanded } = useSidenavExpanded();
  const { user, signout } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const hoverStyle = { color: "white" };

  const handleClick = () => {
    setDropdownOpen(false);
    setSidenavExpanded(false);
  };

  return (
    <Flex direction="column" bg="gray.700" p={2} borderRadius="lg">
      <Flex
        onClick={() => setDropdownOpen(!dropdownOpen)}
        w="full"
        align="center"
        justify="space-between"
      >
        <Avatar size="sm" src={(user as User).photoURL ?? ""} />
        <Box w="calc(100% - 4rem)">
          <Text noOfLines={1} ml={2}>
            {(user as User).email}
          </Text>
        </Box>
        <BiChevronDownCircle color="#718096" fontSize="18px" />
      </Flex>
      {dropdownOpen && (
        <>
          <Divider borderColor="gray.600" />
          <Flex direction="column" pl={2}>
            <NavItem
              onClick={handleClick}
              url="/account"
              expanded={true}
              title="Account"
              icon={MdManageAccounts}
            />
            <NavItem
              onClick={handleClick}
              url="/history"
              expanded={true}
              title="History"
              icon={MdManageHistory}
            />
            <Link
              onClick={() => signout()}
              mb="1"
              rounded="lg"
              p="2"
              role="group"
              _hover={hoverStyle}
              display="flex"
              alignItems="center"
              background={"transparent"}
            >
              <Icon
                color={"gray.300"}
                width={6}
                height={6}
                as={BiLogOutCircle}
                _groupHover={hoverStyle}
              />{" "}
              <Text
                color={"gray.300"}
                fontSize="16"
                fontWeight="semibold"
                ml="3"
                _groupHover={hoverStyle}
              >
                Logout
              </Text>
            </Link>
          </Flex>
        </>
      )}
    </Flex>
  );
};
