import {
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import mixpanel from "mixpanel-browser";
import React, { useState } from "react";
import { FiMail } from "react-icons/fi";

export const BetaModal = ({
  provider,
  open,
  onClose,
}: {
  provider: string;
  open: boolean;
  onClose: (open: boolean) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);

  const sendEmail = async (email: string, provider: string) => {};

  const handleSubmitEmail = async () => {
    setLoading(true);
    mixpanel.track(`request_beta_${provider}`);
    if (!isEmailValid(email)) {
      setLoading(false);
      setIsInvalid(true);
      return;
    }
    setIsInvalid(false);
    await sendEmail(email, provider);
    setLoading(false);
  };

  const handleEmailChange = (e: any) => {
    const value = e.target.value;
    setEmail(value);
  };

  const isEmailValid = (email: string) => {
    if (!email) return false;
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
  };

  return (
    <Modal isCentered isOpen={open} onClose={() => onClose(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading
            as={"span"}
            bgGradient="linear(to-r, primary.400,secondary.400)"
            bgClip="text"
            transform="skew(-4deg)"
            lineHeight={1.1}
            fontSize={{ base: "32px", sm: "3xl", md: "4xl" }}
          >
            Join the Beta
            <Text
              as={"span"}
              bgGradient="linear(to-r, primary.400,secondary.400)"
              bgClip="text"
              transform="skew(30deg)"
            >
              !
            </Text>
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Text mb={8}>
            Lessmail is currently in a closed beta, but were working hard to
            integrate {provider}. For early access, sign up with your email
            below.
          </Text>
          <label>
            <Text>
              <strong>Email</strong>
            </Text>
          </label>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              // eslint-disable-next-line react/no-children-prop
              children={<FiMail />}
            />
            <Input
              type="email"
              isInvalid={isInvalid}
              placeholder="Enter your email to join the beta"
              onChange={handleEmailChange}
              value={email}
            />
          </InputGroup>
          {isInvalid && (
            <Text mt={1} variant="secondary" color="red.300">
              Please enter a valid email
            </Text>
          )}
          <Text mt={2} mb={4} fontSize="xs" variant="secondary">
            We value our users privacy and will never share your email with any
            third parties.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Flex direction="column" w="full">
            <Button isLoading={loading} onClick={handleSubmitEmail} w="full">
              Sign up for the beta!
            </Button>
            {provider === "microsoft" && (
              <Text mt={2} fontSize="xs" w="full">
                I have an account.{" "}
                <Link
                  textDecoration="underline !important"
                  onClick={() => {}}
                  color="primary.400"
                >
                  Continue to {provider} sign in
                </Link>
              </Text>
            )}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
