import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../../context/AuthContext";
import { getSenders } from "../../api/api";
import { IClientUser } from "../../api/types";
import { EQueryKeys } from "../../lib/queryKeys";
import { User } from "firebase/auth";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/provider";
import {
  ISender,
  setFilteredSenders,
  setSenders,
} from "../../store/slices/senderSlice";
import { EmailLabel } from "../../lib/constants";
import { useMailboxFilters } from "./useMailboxFilters";
import { useMailboxLabels } from "./useMailboxLabels";
import { useFilters } from "../../store/selectors";
import { _filterByLabel } from "../store/useSearchFilters";
import { mapObject } from "underscore";
import { IGetSendersResponse, Schema$Filter } from "@/functions/types";
import { usePartialSync } from "../mutations/usePartialSync";
import {
  setLoadingFlowCompleted,
  setMailboxLoading,
  setProgress,
} from "../../store/slices/loadingSlice";

// This hook is responsible for setting the senders in redux once the data is fetched from the API (or retrieved from cache)
// If the user partially loaded the inbox, this hook will handle retrieving the remaining pages of the request recursively until the entire mailbox is loaded.
// Once the entire mailbox is loaded the senders are stored in the "senders" query key and the "SenderPages" query key is cleared.
const getUnsubbedSenders = (filters: Schema$Filter[]) => {
  const unsubbedSenders =
    filters?.reduce((acc: string[], f) => {
      const { criteria, action } = f;
      if (criteria?.from && action?.addLabelIds?.includes(EmailLabel.TRASH)) {
        acc = [...acc, ...criteria.from.split(",")];
      }
      return acc;
    }, []) ?? [];
  return unsubbedSenders;
};

const dedupeSenders = (pages: { senders: ISender[] }[]) => {
  const flatMap = pages.flatMap((page) => page.senders);
  // combine category counts, use the latest lastSendDate, and combine labels
  const deduped = flatMap.reduce((acc: ISender[], sender) => {
    const existingSender = acc.find((prevSender) => {
      return prevSender.email === sender.email;
    });
    const existingSenderIndex = acc.findIndex((prevSender) => {
      return prevSender.email === sender.email;
    });
    if (existingSender) {
      // Update the original sender object (coming from a different batch result) with new counts. Reduce array to unique senders.
      const combinedSenderObject = {
        ...existingSender,
        categoryCounts: mapObject(
          existingSender.categoryCounts,
          (val: number, key: string) => {
            return (
              sender.categoryCounts[key as keyof ISender["categoryCounts"]] +
              val
            );
          }
        ),
      };
      acc[existingSenderIndex] = combinedSenderObject;
      return acc;
    }
    acc.push(sender);
    return acc;
  }, []);
  return deduped;
};

export const useGetMailbox = () => {
  console.log("useGetMailbox rendered");
  const dispatch = useAppDispatch();
  const { user, oauthToken, appCheckToken } = useAuth();
  const authObject = {
    ...user,
    oauthToken,
    appCheckToken,
  };

  const { filters } = useMailboxFilters();
  const { labels } = useMailboxLabels();
  const { labelFilter } = useFilters();
  const queryClient = useQueryClient();
  const { syncMailbox } = usePartialSync();
  const mailboxLoading = useAppSelector(
    (state) => state.loading.mailboxLoading
  );

  const existingSenders = queryClient.getQueryData<ISender[]>([
    EQueryKeys.Senders,
    (user as User).uid,
  ]);
  const enabled = !!user && !!oauthToken && !!appCheckToken && !existingSenders;

  const {
    data: pageData,
    hasNextPage,
    fetchNextPage,
    isFetched,
    isFetching,
    isError,
    refetch,
  } = useInfiniteQuery({
    enabled,
    queryKey: [EQueryKeys.SenderPages, (user as User).uid],
    retry: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    // @ts-ignore
    queryFn: (data) => {
      return getSenders({
        auth: authObject as IClientUser,
        pageParam: data?.pageParam,
      });
    },
    initialPageParam: null,
    getNextPageParam: (lastPage) => lastPage.next,
  });

  const formatSenderInformation = (
    senders: ISender[],
    filters: Schema$Filter[]
  ) => {
    const unsubbedSenders = getUnsubbedSenders(filters);
    senders.forEach((sender) => {
      if (unsubbedSenders.includes(sender.email)) {
        sender.isUnsubscribed = true;
      }
    });
    const filteredSenders = _filterByLabel(
      senders,
      labelFilter ?? {
        labelId: EmailLabel.INBOX,
        labelName: EmailLabel.INBOX,
        and: [],
      }
    );

    return {
      senders,
      filteredSenders,
    };
  };

  const trackLoadingProgress = () => {
    const lastPage = pageData?.pages[pageData.pages.length - 1];
    const messageTotal = (lastPage as IGetSendersResponse).messageTotal!;
    const numOfResults = (pageData?.pages as { senders: ISender[] }[])
      .flatMap((page) => page.senders)
      .flat()
      // combine category counts
      .reduce((acc, sender) => {
        return acc + sender.categoryCounts.total;
      }, 0);
    console.log({ numOfResults, messageTotal });
    // Get rounded value of percentage loaded as a whole number
    const percentageLoaded = Math.round((numOfResults / messageTotal) * 100);
    dispatch(setProgress(percentageLoaded));
  };

  useEffect(() => {
    const doneFetching =
      isFetched && !hasNextPage && pageData && labels && !isError;
    if (hasNextPage && !isFetching && !isError) {
      trackLoadingProgress();
      fetchNextPage();
    } else if (doneFetching) {
      trackLoadingProgress();
      // set the senders in redux and query client cache once all the pages are fetched
      const senders = dedupeSenders(pageData.pages as { senders: ISender[] }[]);
      const { filteredSenders } = formatSenderInformation(
        senders,
        filters ?? []
      );
      dispatch(setFilteredSenders(filteredSenders));
      dispatch(setSenders(senders));

      queryClient.setQueryData(
        [EQueryKeys.Senders, (user as User).uid],
        senders
      );

      // set the historyId in the query client cache
      queryClient.setQueryData(
        [EQueryKeys.HistoryId, (user as User).uid],
        (pageData.pages as IGetSendersResponse[])[pageData.pages.length - 1]
          .historyId
      );

      dispatch(setMailboxLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isError,
    hasNextPage,
    isFetched,
    isFetching,
    pageData,
    labels,
    fetchNextPage,
  ]);

  useEffect(() => {
    if (existingSenders) {
      // skips any changes to the persistedQueryClient if the data is already fetched and senders are already populated.
      const { senders, filteredSenders } = formatSenderInformation(
        existingSenders,
        filters ?? []
      );
      console.log({ senders });
      dispatch(setFilteredSenders(filteredSenders));
      dispatch(setSenders(senders));
      dispatch(setMailboxLoading(false));

      const loadingFlowCompleted =
        (queryClient.getQueryData([
          EQueryKeys.LoadingFlowCompelted,
          (user as User).uid,
        ]) as boolean) ?? false;
      if (loadingFlowCompleted === true) {
        dispatch(setLoadingFlowCompleted());
      }

      syncMailbox();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    mailboxLoading,
    fullSync: refetch,
  };
};
