import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IGmailMessage {
  id: string;
  threadId?: string;
  from: string;
  to?: string;
  date?: string;
  subject?: string;
  labelIds?: string[];
  snippet?: string;
  selected?: boolean;
}
export interface IModifyMessageLabelPayload {
  messageId: string;
  addLabelIds?: string[];
  removeLabelIds?: string[];
}

const initialState: IGmailMessage[] = [];

const messageSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    addMessages: (state, action: PayloadAction<IGmailMessage[]>) => {
      // since it's possible for there to be duplicate messages, we need to ensure that only new messages are stored in state
      const newMessages = action.payload.filter(
        (message) => !state.find((m) => m.id === message.id)
      );
      return [...state, ...newMessages];
    },
    selectMessages: (state, action: PayloadAction<IGmailMessage["id"][]>) => {
      return state.map((message) => {
        if (action.payload.includes(message.id)) {
          return {
            ...message,
            selected: true,
          };
        }
        return message;
      });
    },
    deselectMessages: (state, action: PayloadAction<IGmailMessage["id"][]>) => {
      return state.map((message) => {
        if (action.payload.includes(message.id)) {
          return {
            ...message,
            selected: false,
          };
        }
        return message;
      });
    },
    modifyMessageLabels: (
      state,
      action: PayloadAction<IModifyMessageLabelPayload[]>
    ) => {
      const newMessages: IGmailMessage[] = state.map((message) => {
        if (action.payload.find((m) => m.messageId === message.id)) {
          return {
            ...message,
            labelIds: [
              ...(message.labelIds ?? []),
              ...(action.payload.find((m) => m.messageId === message.id)
                ?.addLabelIds ?? []),
            ].filter(
              (labelId) =>
                !action.payload
                  .find((m) => m.messageId === message.id)
                  ?.removeLabelIds?.includes(labelId)
            ),
          } as IGmailMessage;
        }
        return message;
      });
      return newMessages;
    },
  },
});

export const {
  addMessages,
  selectMessages,
  deselectMessages,
  modifyMessageLabels,
} = messageSlice.actions;

export default messageSlice.reducer;
