import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  button: {
    // this will style the MenuButton component
    _hover: {
      bg: "primary.600",
      color: "white",
    },
    _focus: {
      bg: "primary.600 !important",
    },
    _active: {
      bg: "primary.600",
    },
  },
  list: {
    // this will style the MenuList component
    p: "2",
    border: "none",
    bg: "gray.800",
  },
  item: {
    // this will style the MenuItem and MenuItemOption components
    color: "gray.200",
    borderRadius: "md",
    bg: "gray.800",
    _hover: {
      bg: "gray.700",
    },
    _focus: {
      bg: "gray.900",
    },
  },

  command: {
    // this will style the text defined by the command
    // prop in the MenuItem and MenuItemOption components
  },
  divider: {
    // this will style the MenuDivider component
  },
});
// export the base styles in the component theme
export const menuTheme = defineMultiStyleConfig({ baseStyle });
