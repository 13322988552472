import { Badge, Box, Flex, Text } from "@chakra-ui/react";
import {
  ISender,
  deSelectSenders,
  selectSenders,
} from "../../store/slices/senderSlice";
import { memo, useMemo, useState } from "react";
import { useMessageView } from "../../context/MessageViewContext";
import { useLongPress } from "../../hooks/common/useLongPress";
import { useClickOutside } from "../../hooks/common/useClickOutside";
import { formatLastSendDate } from "../../utilities/date";
import { ContextMenu } from "./contextMenu";
import { SelectionArea } from "./selectionArea";
import { CardBody } from "./cardBody";
import { useLabelFilter } from "../../store/selectors";
import { useAppDispatch, useAppSelector } from "../../store/provider";
import { store } from "../../store/store";
import {
  deselectMessages,
  selectMessages,
} from "../../store/slices/messageSlice";
import { useIsSelectionViewActive } from "../../context/SelectionViewContext";

interface ISenderCardProps {
  senderFromStore: ISender;
}

export const SenderCard = memo(
  ({ senderFromStore }: ISenderCardProps) => {
    const sender = useAppSelector(
      (state) => state.senders.find((s) => s.email === senderFromStore.email)!
    );
    const dispatch = useAppDispatch();
    const isMobile = window.innerWidth < 768;

    const labelFilter = useLabelFilter();
    const {
      focusedSender,
      isMessageViewOpen,
      setMessageViewOpen,
      setFocusedSender,
    } = useMessageView();
    const { setIsSelectionViewActive } = useIsSelectionViewActive();

    const longPressProps = useLongPress(() => {
      setIsContextMenuOpen(true);
    });
    const ref = useClickOutside<HTMLDivElement>(() => {
      setIsContextMenuOpen(false);
    });
    const lastSendDate = formatLastSendDate(sender.lastSendDate);

    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
    const [isHovered] = useState(false);

    const bg = useMemo(() => {
      if (sender.selected || sender.indeterminate) return "rgba(73,61,166,.2)";
      if (sender.isUnsubscribed && focusedSender !== sender.email)
        return "rgba(255,255,200,.1)";
      if (isMessageViewOpen && focusedSender === sender.email) {
        return "primary.600";
      } else if (!isMobile) return "gray.800";
      else return "none";
    }, [sender, focusedSender, isMessageViewOpen, isMobile]);
    if (sender.email === "awards@mp1.tripadvisor.com") {
      console.log("sender", sender);
    }

    const handleOpenMessageView = () => {
      setMessageViewOpen(true);
      setFocusedSender(sender.email);
    };

    function handleContextMenuClick(
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) {
      e.preventDefault();
      setIsContextMenuOpen(true);
    }

    const handleSelect = (emailAddress: string) => {
      const sendersFromStore = store.getState().senders;
      const messages = store.getState().messages;

      const senderFromStore = sendersFromStore.find(
        (s) => s.email === emailAddress
      );
      const isSenderAlreadySelected_or_indeterminate =
        senderFromStore?.selected || senderFromStore?.indeterminate;
      const selectedSenderMessages = messages.filter(
        (message) => message.from === emailAddress
      );

      if (isSenderAlreadySelected_or_indeterminate) {
        dispatch(deSelectSenders([emailAddress]));
        dispatch(
          deselectMessages(selectedSenderMessages.map((message) => message.id))
        );
      } else {
        dispatch(
          selectSenders({
            emails: [emailAddress],
            currentLabel: labelFilter.labelId,
          })
        );
        dispatch(
          selectMessages(selectedSenderMessages.map((message) => message.id))
        );
      }

      const selectionViewActive = store
        .getState()
        .senders.filter((s) => s.selected || s.indeterminate).length;
      setIsSelectionViewActive(!!selectionViewActive);
    };

    const handleSelectSender = (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      handleSelect(sender.email);
    };

    return (
      <Box
        ref={ref}
        {...longPressProps}
        onContextMenu={handleContextMenuClick}
        onClick={handleOpenMessageView}
        rounded="lg"
        key={sender.email}
        p={3}
        background={bg}
        _hover={{ color: "white", boxShadow: "lg", cursor: "pointer" }}
        boxShadow={{ base: "none", md: isMessageViewOpen ? "none" : "md" }}
        pos="relative"
        w={{ base: "calc(100% - 32px)", md: "98%" }}
        mx={{ base: "auto", md: 1 }}
        borderWidth={{ base: "none", md: isMessageViewOpen ? "none" : "1px" }}
        borderColor={
          sender.selected || sender.indeterminate ? "primary.500" : "gray.700"
        }
      >
        <ContextMenu
          onClose={() => setIsContextMenuOpen(false)}
          isOpen={isContextMenuOpen}
          email={sender.email}
        />
        <Flex align="center" minW={0}>
          <SelectionArea
            isHovered={isHovered}
            sender={sender}
            isSelectionViewActive={false} // TODO: implement selection view
            onSelectionAreaClick={handleSelectSender}
          />
          <CardBody sender={sender} />
          <Flex flexDirection="column">
            <Badge
              background="unset"
              color="white"
              maxH="18px"
              rounded="md"
              alignSelf="flex-end"
            >
              <Box alignSelf="top">
                {sender.categoryCounts[labelFilter.labelId] ??
                  sender.categoryCounts.total}
              </Box>
            </Badge>
            <Text ml={2} fontSize="sm" variant="secondary">
              {lastSendDate}
            </Text>
          </Flex>
        </Flex>
      </Box>
    );
  },
  () => true
);
