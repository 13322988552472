// https://tanstack.com/query/latest/docs/react/guides/optimistic-updates
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAppDispatch } from "../../store/provider";
import { postArchive } from "../../api/api";
import { EQueryKeys } from "../../lib/queryKeys";
import {
  IModifyLabelPayload,
  ISender,
  deSelectSenders,
  modifySenderLabels,
  setSenders,
} from "../../store/slices/senderSlice";
import { EmailLabel } from "../../lib/constants";
import { useAuth } from "../../context/AuthContext";
import { User } from "firebase/auth";
import { IClientUser } from "../../api/types";
import {
  IModifyMessageLabelPayload,
  deselectMessages,
  modifyMessageLabels,
} from "../../store/slices/messageSlice";
import { ISelectedSender, PaginatedMessageQuery } from "../../lib/types";
import { useIsSelectionViewActive } from "../../context/SelectionViewContext";
import { getMessageIdsFromSender } from "../../utilities/helpers";
import { useSetCompletedAction } from "../../store/slices/actionSlice";
import { EActionTypes, IArchiveResponse } from "@/functions/types";

export const useArchive = () => {
  const { setIsSelectionViewActive } = useIsSelectionViewActive(); // triggers necessary UI re-renders after actions.
  const setCompletedAction = useSetCompletedAction();

  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const { user, oauthToken, appCheckToken } = useAuth();
  const authObject = {
    ...user,
    oauthToken,
    appCheckToken,
  };

  const optimisticallyUpdateSenders = (
    sendersWithMessagesSelected: ISelectedSender[]
  ) => {
    const previousSenders = queryClient.getQueryData<ISender[]>([
      EQueryKeys.Senders,
      (user as User)?.uid,
    ]);

    const transaction: IModifyLabelPayload[] = sendersWithMessagesSelected.map(
      (selectedSender) => {
        return {
          email: selectedSender.email,
          labelChanges: [
            {
              id: EmailLabel.INBOX,
              count: -selectedSender.count,
            },
          ],
        };
      }
    );

    if (transaction.length) {
      // Optimistically update the senders in redux
      dispatch(modifySenderLabels(transaction));
    }

    return { previousSenders };
  };

  const optimisticallyUpdateMessages = (
    sendersWithMessagesSelected: ISelectedSender[],
    selectedMessageIds: string[]
  ) => {
    const transaction: IModifyMessageLabelPayload[] = selectedMessageIds.map(
      (id) => {
        return {
          messageId: id,
          removeLabelIds: [EmailLabel.INBOX],
        };
      }
    );

    // Optimistically update the messages in redux
    dispatch(modifyMessageLabels(transaction));

    // invalidate the queries for the messages that were archived.
    const previousMessageQueries = sendersWithMessagesSelected.forEach((s) => {
      queryClient.invalidateQueries({
        queryKey: [
          EQueryKeys.Messages,
          (user as User)?.uid,
          EmailLabel.INBOX,
          s.email,
        ],
      });
    });

    return { previousMessageQueries };
  };

  const rollbackMessageMutation = (
    previousMessageQueries: PaginatedMessageQuery[],
    prevSelection: ISelectedSender[]
  ) => {
    previousMessageQueries.forEach((q) => {
      const messages = q.pages.flatMap((p) => p.messages);
      queryClient.refetchQueries({
        queryKey: [
          EQueryKeys.Messages,
          (user as User)?.uid,
          EmailLabel.INBOX,
          messages[0].from,
        ],
      });
    });
    dispatch(
      modifyMessageLabels(
        prevSelection.flatMap((selectedSender) => {
          return selectedSender.messageIds.map((id) => {
            return {
              messageId: id,
              addLabelIds: [EmailLabel.INBOX],
            };
          });
        })
      )
    );
  };

  const rollbackSenderMutation = (previousSenders: ISender[]) => {
    queryClient.setQueryData<ISender[]>(
      [EQueryKeys.Senders, (user as User)?.uid],
      previousSenders
    );
    dispatch(setSenders(previousSenders));
  };

  const { mutateAsync: archiveSelection, isPending: isArchiveLoading } =
    useMutation({
      mutationKey: [EQueryKeys.Archive],
      mutationFn: ({
        sendersWithMessagesSelected,
        senders,
      }: {
        senders: ISender[];
        sendersWithMessagesSelected: ISelectedSender[];
        selectedMessageIds: string[];
      }) =>
        postArchive({
          auth: authObject as IClientUser,
          messageGroups: sendersWithMessagesSelected.reduce(
            (acc, selectedSender) => ({
              ...acc,
              [selectedSender.email]: getMessageIdsFromSender({
                selectedSender,
                sender: senders.find((s) => s.email === selectedSender.email)!,
              }),
            }),
            {}
          ),
        }),
      onMutate: async ({
        sendersWithMessagesSelected,
        selectedMessageIds,
      }: {
        sendersWithMessagesSelected: ISelectedSender[];
        selectedMessageIds: string[];
      }) => {
        const { previousSenders } = optimisticallyUpdateSenders(
          sendersWithMessagesSelected
        );
        const { previousMessageQueries } = optimisticallyUpdateMessages(
          sendersWithMessagesSelected,
          selectedMessageIds
        );

        const sendersToDeSelect = sendersWithMessagesSelected.map(
          (s) => s.email
        );
        dispatch(deSelectSenders(sendersToDeSelect));
        dispatch(deselectMessages(selectedMessageIds));

        setIsSelectionViewActive(false);
        setCompletedAction("ARCHIVE" as EActionTypes);

        return {
          previousSenders,
          previousMessageQueries,
          previousSendersWithMessagesSelected: sendersWithMessagesSelected,
        };
      },
      onError: (error, _, context) => {
        console.log({ error, context });
        if (context?.previousSenders) {
          rollbackSenderMutation(context.previousSenders);
        }
        if (
          context?.previousMessageQueries &&
          context?.previousSendersWithMessagesSelected
        ) {
          rollbackMessageMutation(
            context.previousMessageQueries as PaginatedMessageQuery[],
            context.previousSendersWithMessagesSelected
          );
        }
      },
      onSuccess: (data: IArchiveResponse) => {
        // set the history in the query client
        queryClient.setQueryData<string>(
          [EQueryKeys.HistoryId, (user as User)?.uid],
          data.historyId
        );

        // remove inbox categoryCounts from senders who were archived
        queryClient.setQueryData<ISender[]>(
          [EQueryKeys.Senders, (user as User)?.uid],
          (old) => {
            return old!.map((sender) => {
              const selectedSender = Object.keys(data.messageGroups).find(
                (email) => email === sender.email
              );
              if (selectedSender) {
                return {
                  ...sender,
                  categoryCounts: {
                    ...sender.categoryCounts,
                    [EmailLabel.INBOX]:
                      sender.categoryCounts[EmailLabel.INBOX] -
                      data.messageGroups[selectedSender].length,
                  },
                };
              }
              return sender;
            });
          }
        );
      },
    });

  return { archive: archiveSelection, isArchiveLoading };
};
