import React from "react";

interface SideNavContext {
  sidenavExpanded: boolean;
  setSidenavExpanded: (expanded: boolean) => void;
}

export const sidenavContext = React.createContext<SideNavContext>({
  sidenavExpanded: false,
  setSidenavExpanded: () => {},
});

export function useSidenavExpanded() {
  const context = React.useContext(sidenavContext);
  if (!context) {
    throw new Error("useSidenavExpanded must be used within a SidenavProvider");
  }
  return context;
}

export function SidenavProvider({ children }: any) {
  const initialSideNavOpenState = window.innerWidth > 768;
  const [sidenavExpanded, setSidenavExpanded] = React.useState(
    initialSideNavOpenState
  );

  const contextValue = {
    sidenavExpanded,
    setSidenavExpanded,
  };

  return (
    <sidenavContext.Provider value={contextValue}>
      {children}
    </sidenavContext.Provider>
  );
}
