import { Flex, Box } from "@chakra-ui/react";
import { AiOutlineCheck, AiOutlineLine } from "react-icons/ai";
import { ISender } from "../../store/slices/senderSlice";
import SenderAvatar from "../ui/senderAvatar";
import { getEmailEnding } from "../../utilities/helpers";

interface ISelectionAreaProps {
  sender: ISender;
  isSelectionViewActive: boolean;
  isHovered: boolean;
  onSelectionAreaClick: (e: any) => void;
}

export const SelectionArea = ({
  sender,
  isHovered,
  onSelectionAreaClick,
}: ISelectionAreaProps) => {
  return (
    <Box onClick={onSelectionAreaClick} pos="relative">
      {!isHovered && !sender.selected && !sender.indeterminate && (
        <SenderAvatar
          senderName={sender.name}
          urlEnding={getEmailEnding(sender.email)}
          alt={sender.name}
        />
      )}

      {(isHovered || sender.selected || sender.indeterminate) && (
        <Flex
          zIndex={100}
          align="center"
          justify="center"
          bg={
            sender.selected || sender.indeterminate ? "secondary.500" : "none"
          }
          mr={4}
          borderRadius="full"
          h="42px"
          w="42px"
          borderWidth={3}
          borderColor="whiteAlpha.600"
        >
          {sender.selected && <AiOutlineCheck />}
          {sender.indeterminate && <AiOutlineLine />}
        </Flex>
      )}
    </Box>
  );
};
