import { ISelectedSender } from "../lib/types";
import { EMailBoxFilters } from "../store/slices/searchSlice";
import { ISender } from "../store/slices/senderSlice";
import { ESortOptions } from "../store/slices/sortSlice";

export const getEmailEnding = (email: string) => {
  if (!email) return "";
  if (!email.split("@")[1]) return email;
  const end = email.split("@")[1]?.split(".");
  const urlEnding = `${end[end.length - 2]}.${end[end.length - 1]}`;
  return urlEnding;
};

export const getMessageIdsFromSender = ({
  sender,
  selectedSender,
}: {
  sender: ISender;
  selectedSender: ISelectedSender;
}) => {
  if (sender.selected) {
    return []; // all in label are selected and we should fetch messages on backend to get all ids.
  }
  return selectedSender.messageIds; // messages are selected by the user, so we send these ids to the backend.
};

export const getCategoryKey = (labelId: string) => {
  switch (labelId) {
    case EMailBoxFilters.ALL:
    case EMailBoxFilters.LISTS:
      return "total";
    default:
      return labelId;
  }
};

export const handleSort = ({
  senders,
  type,
  labelId,
}: {
  senders: ISender[];
  type?: ESortOptions;
  labelId: string;
}) => {
  switch (type) {
    case ESortOptions.Latest:
      return senders.sort((a, b) => b.lastSendDate! - a.lastSendDate!);
    case ESortOptions.Oldest:
      return senders.sort((a, b) => a.lastSendDate! - b.lastSendDate!);
    case ESortOptions.MessageNum:
      console.log("sorted by message num");
      return senders.sort(
        (a, b) =>
          b.categoryCounts[getCategoryKey(labelId)] -
          a.categoryCounts[getCategoryKey(labelId)]
      );
    default:
      console.log("default");
      return senders.sort(
        (a, b) =>
          b.categoryCounts[getCategoryKey(labelId)] -
          a.categoryCounts[getCategoryKey(labelId)]
      );
  }
};
