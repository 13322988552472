export enum EQueryKeys {
  AppcheckToken = "appcheck-token",
  OauthToken = "oauth-token",
  OauthExpiry = "oauth-expiry",
  SenderPages = "sender-pages",
  Senders = "senders",
  Filters = "filters",
  Labels = "labels",
  Messages = "messages",
  Archive = "archive",
  UndoArchive = "undo-archive",
  Trash = "trash",
  ApplyLabels = "apply-labels",
  CreateLabel = "create-label",
  History = "history",
  Unsubscribe = "unsubscribe",
  HistoryId = "history-id",
  Sync = "sync",
  LoadingFlowCompelted = "loading-flow-completed",
}
