import { useState, useCallback, useEffect } from "react";

export function useLongPress(callback = () => {}, ms = 800) {
  const [startLongPress, setStartLongPress] = useState(false);

  useEffect(() => {
    let timerId: any;
    if (startLongPress) {
      timerId = setTimeout(callback, ms);
    } else {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [callback, ms, startLongPress]);

  const start = useCallback(() => {
    setStartLongPress(true);
  }, []);
  const stop = useCallback(() => {
    setStartLongPress(false);
  }, []);

  return {
    onMouseDown: start,
    onMouseUp: stop,
    onMouseLeave: stop,
    onTouchStart: start,
    onTouchEnd: stop,
  };
}

// Component usage example

// const MyComponent = () => {
//   const onLongPress = () => {
//     console.log("Long press is triggered");
//   };

//   const onClick = () => {
//     console.log("Click is triggered");
//   };

//   const handlers = useLongPress(onLongPress, onClick, 500); // Long press for 500ms

//   return <div {...handlers}>Press me or hold me</div>;
// };

// export default MyComponent;
