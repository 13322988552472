import { useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { BottomActionButtons } from "./bottomActionButtons";
import { LabelModal } from "../modals/labelModal";
import {
  useSelectedMessageCount,
  useSendersWithMessagesSelected,
} from "../../store/selectors";

export const BottomActions = () => {
  const [labelModalOpen, setLabelModalOpen] = useState(false);

  const sendersWithMessagesSelected = useSendersWithMessagesSelected();
  const selectedMessageCount = useSelectedMessageCount();
  return (
    <>
      <Flex
        display={selectedMessageCount > 0 ? "flex" : "none"}
        direction="column"
        rounded="lg"
        borderBottomRadius={0}
        bg={{ base: "primary.500", md: "gray.900" }}
        w={{ base: "unset", md: "calc(100vw - 280px)" }}
        overflowX="hidden"
        h={{
          md: "160px",
        }}
        px={4}
        pb={4}
        pt={2}
      >
        <LabelModal
          open={labelModalOpen}
          onClose={() => setLabelModalOpen(false)}
        />

        <Flex pos="relative" align="center" justify="between" mb={2}>
          <Text
            fontSize={{
              base: "xs",
              md: "md",
            }}
            variant="secondary"
          >
            Selected:
          </Text>
          <Text fontSize="sm" variant="secondary">
            <strong style={{ paddingRight: 4, paddingLeft: 4, color: "white" }}>
              {selectedMessageCount}
            </strong>
            email{selectedMessageCount > 1 && "s"} from
            <strong style={{ paddingRight: 4, paddingLeft: 4, color: "white" }}>
              {sendersWithMessagesSelected.length}
            </strong>
            sender{sendersWithMessagesSelected.length > 1 && "s"}
          </Text>
        </Flex>
        <BottomActionButtons setLabelModalOpen={setLabelModalOpen} />
      </Flex>
    </>
  );
};
