import React from "react";
import { Button, IconButton, ButtonProps } from "@chakra-ui/react";
import mixpanel from "mixpanel-browser";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../lib/firebaseConfig";

interface AnalyticsButtonProps extends ButtonProps {
  eventName: string;
  metaData: object;
  children?: React.ReactNode;
  onClick: () => void;
  buttonType?: "icon" | "button";
  ariaLabel: string;
  disabled?: boolean;
  leftIcon?: React.ReactElement;
  icon?: React.ReactElement;
  loading?: boolean;
}

const AnalyticsButton = ({
  eventName,
  metaData,
  children,
  onClick,
  buttonType,
  ariaLabel,
  disabled,
  loading,
  ...props
}: AnalyticsButtonProps) => {
  const handleClick = () => {
    mixpanel.track(eventName, metaData);
    if (eventName !== "purchase") {
      // Use actually used the app.
      logEvent(analytics, "any_action");
    }
    onClick();
  };

  if (buttonType === "icon") {
    return (
      <IconButton
        _hover={{ bacgroundColor: "transparent" }}
        {...props}
        aria-label={ariaLabel}
        onClick={handleClick}
      />
    );
  } else
    return (
      <Button
        isDisabled={disabled}
        isLoading={loading}
        onClick={handleClick}
        {...props}
      >
        {children}
      </Button>
    );
};

export default AnalyticsButton;
