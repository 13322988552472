import { Flex } from "@chakra-ui/react";
import { useGetMailbox } from "../hooks/queries/useGetMailbox";
import { DashboardBody } from "../components/dashboard/body";
import { DashboardHeader } from "../components/dashboard/header";
import { BottomActions } from "../components/bottomActions/index";
import { SelectionViewProvider } from "../context/SelectionViewContext";
import LoadingScreen from "./loading";
import { useAppSelector } from "../store/provider";

const Dashboard = () => {
  console.log("dashboard rendered");
  const { mailboxLoading } = useGetMailbox();
  // const isMobile = window.innerWidth < 768;
  // const { loadingFlowCompleted } = useLoadingFlow();
  const loadingFlowCompleted = useAppSelector(
    (state) => state.loading.loadingFlowCompleted
  );

  if (mailboxLoading || !loadingFlowCompleted) return <LoadingScreen />;

  return (
    <SelectionViewProvider>
      <Flex maxH="100vh" pt={4} flex={1} overflow="hidden">
        <Flex direction="column" flex={1}>
          <DashboardHeader />
          <DashboardBody />

          {/* {!isMobile && (
          <Flex align="center" justify="space-between" py={4}>
            {selectedMessageCount === 0 && <PendingActions />}
          </Flex>
        )} */}
          <BottomActions />
        </Flex>
      </Flex>
    </SelectionViewProvider>
  );
};

export default Dashboard;
