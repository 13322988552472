import { Button, useMediaQuery } from "@chakra-ui/react";

export default function ImageButton({ src, alt, children, ...props }: any) {
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  return (
    <Button
      bg="primary.700"
      rounded="lg"
      display="flex"
      justifyContent="flex-start"
      w="full"
      p={0}
      size="lg"
      {...props}
    >
      <div
        style={{
          width: 48,
          height: 48,
          borderTopLeftRadius: ".5rem",
          borderBottomLeftRadius: ".5rem",
          marginRight: "1rem",
          background: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <img
          src={src}
          style={{
            objectFit: "contain",
            height: 32,
            width: 32,
          }}
          alt={alt}
        />
      </div>
      <div style={{ fontSize: isMobile ? 14 : 18 }}>{children}</div>
    </Button>
  );
}
