import { EActionTypes } from "@/functions/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "../provider";

const actionSlice = createSlice({
  name: "actions",
  initialState: null,
  reducers: {
    setCompletedAction: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setCompletedAction } = actionSlice.actions;

export const useSetCompletedAction = () => {
  const dispatch = useAppDispatch();
  return (action: EActionTypes) => {
    dispatch(setCompletedAction(action));
  };
};

export default actionSlice.reducer;
