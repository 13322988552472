import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMailboxLabels } from "../../hooks/queries/useMailboxLabels";
import { EFilterTypes } from "../../store/slices/searchSlice";
import { useFilters } from "../../store/selectors";
import { useMessageView } from "../../context/MessageViewContext";
import { useSidenavExpanded } from "../../context/SidenavContext";
import { useSearchFilters } from "../../hooks/store/useSearchFilters";
import { AddLabelModal } from "../modals/addLabelModal";
import { Schema$Label } from "@/functions/types";

interface HeirachicalLabels {
  [key: string]: LabelWithChildren;
}

interface LabelWithChildren {
  label: Schema$Label;
  children: HeirachicalLabels;
}

export const LabelSection = () => {
  const { labels } = useMailboxLabels();
  console.log({ labels });
  const [showAddLabelModal, setShowAddLabelModal] = useState(false);

  const filteredLabels = labels?.filter((label) => {
    return label.type === "user";
  });

  const labelsForUI = filteredLabels?.reduce(
    (acc: HeirachicalLabels, label) => {
      let currentLevel = acc;
      const nameParts = label.name?.split("/") || [];
      nameParts.forEach((namePart, index) => {
        if (!currentLevel[namePart]) {
          currentLevel[namePart] = {
            label: label,
            children: {},
          };
        }
        if (index === nameParts.length - 1) {
          currentLevel[namePart].label = label;
        }
        currentLevel = currentLevel[namePart].children;
      });
      return acc;
    },
    {}
  );
  console.log({ labelsForUI });

  const onAddLabel = (e: any) => {
    e.preventDefault();
    setShowAddLabelModal(true);
  };

  return (
    <Accordion mt={6} defaultIndex={0} allowToggle>
      <AddLabelModal
        open={showAddLabelModal}
        onClose={() => setShowAddLabelModal(false)}
      />
      <AccordionItem
        _last={{
          borderBottomWidth: 0,
        }}
        borderBottomWidth={0}
        borderTopWidth={0}
      >
        <h2 style={{ marginBottom: 0, display: "flex" }}>
          <AccordionButton
            _hover={{
              bg: "transparent",
            }}
            p={0}
            display="flex"
            flex={1}
            justifyContent="space-between"
            mb={3}
          >
            <Heading fontSize="md" textAlign="left" m={0}>
              Labels
            </Heading>
            <div>
              <Box
                px={2}
                pb={1}
                borderRadius="md"
                _hover={{
                  cursor: "pointer",
                  background: "primary.200",
                }}
                onClick={onAddLabel}
                as="span"
                fontWeight="bold"
              >
                +
              </Box>
              <AccordionIcon />
            </div>
          </AccordionButton>
        </h2>
        <AccordionPanel p={0}>
          <Flex direction="column" flex={1} align="start" w="full">
            {Object.values(labelsForUI!).map((labelWithChildren) => (
              <NestedLabel
                key={labelWithChildren.label.id}
                labelWithChildren={labelWithChildren}
              />
            ))}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

const NestedLabel = ({
  labelWithChildren,
  nestingLevel = 1,
}: {
  labelWithChildren: LabelWithChildren;
  nestingLevel?: number;
}) => {
  const { labelFilter } = useFilters();
  const { onLabelPress } = useSearchFilters();
  const { isMessageViewOpen, focusedSender } = useMessageView();
  const { setSidenavExpanded } = useSidenavExpanded();

  const location = useLocation();
  const navigate = useNavigate();

  const label = labelWithChildren.label;

  const hasChildLabels = Object.keys(labelWithChildren.children).length > 0;
  const bg = useMemo(() => {
    if (labelFilter.labelId === label.id && location.pathname === "/") {
      return "primary.400";
    } else return "transparent";
  }, [labelFilter, label.id, location.pathname]);

  if (hasChildLabels) {
    return (
      <Accordion allowToggle w="full">
        <AccordionItem
          w="full"
          _hover={{
            bg: "transparent",
          }}
          _last={{
            borderBottomWidth: 0,
          }}
          borderBottomWidth={0}
          borderTopWidth={0}
        >
          <Flex
            onClick={() => {
              console.log("happening");
              onLabelPress({
                id: label.id as string,
                filterType: EFilterTypes.LabelFilter,
              });
              setSidenavExpanded(false);
              navigate("/");
            }}
            flex={1}
            justify="space-between"
            align="center"
            mb={2}
            py={0.5}
            pl={nestingLevel === 1 ? 2 : nestingLevel}
            rounded="md"
            _hover={{
              bg: "primary.400",
              cursor: "pointer",
            }}
            bg={bg}
          >
            <Flex w="200px" align="center">
              <Flex
                style={{
                  backgroundColor: label.color?.backgroundColor ?? "gray.300",
                  zIndex: 1,
                }}
                mr={3}
                width="12px"
                height="12px"
                borderRadius="6px"
                backgroundColor={
                  label.color
                    ? (label.color.backgroundColor as string)
                    : "gray.300"
                }
              />
              <Text noOfLines={1} variant="secondary">
                {label.name}
              </Text>
            </Flex>
            <AccordionButton
              onClick={(e) => e.stopPropagation()}
              w={7 - nestingLevel}
              _focus={{ bg: "unset" }}
              p={0}
            >
              <AccordionIcon />
            </AccordionButton>
          </Flex>
          <AccordionPanel p={0} pl={nestingLevel + 2}>
            <Flex direction="column" flex={1} w="full">
              {Object.values(labelWithChildren.children).map(
                (childLabelWithChildren) => (
                  <NestedLabel
                    nestingLevel={nestingLevel + 1}
                    key={childLabelWithChildren.label.id}
                    labelWithChildren={childLabelWithChildren}
                  />
                )
              )}
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  } else {
    const handleLabelClick = () => {
      if (isMessageViewOpen && focusedSender) {
        console.log("happening");
        // getMessages({
        //   email: focusedSender,
        //   labelId: label.id as string,
        //   defaultSelected: selectedSenders.includes(focusedSender),
        // });
      }
      onLabelPress({
        id: label.id as string,
        filterType: EFilterTypes.LabelFilter,
      });
      setSidenavExpanded(false);
      navigate("/");
    };
    return (
      <Flex
        rounded="md"
        w="full"
        onClick={handleLabelClick}
        mb={2}
        align="center"
        p={0.5}
        pl={2}
        _hover={{
          bg: "primary.400",
          cursor: "pointer",
        }}
        bg={bg}
      >
        <Box
          w="200px"
          mr={3}
          width="12px"
          height="12px"
          borderRadius="6px"
          bg={
            label.color ? (label.color.backgroundColor as string) : "gray.300"
          }
        />
        <Text noOfLines={1} variant="secondary">
          {label.name}
        </Text>
      </Flex>
    );
  }
};
