import { configureStore } from "@reduxjs/toolkit";
import senderSlice from "./slices/senderSlice";
import filterSlice from "./slices/searchSlice";
import messageSlice from "./slices/messageSlice";
import sortSlice from "./slices/sortSlice";
import actionSlice from "./slices/actionSlice";
import loadingSlice from "./slices/loadingSlice";

export const store = configureStore({
  reducer: {
    senders: senderSlice,
    filters: filterSlice,
    messages: messageSlice,
    sortType: sortSlice,
    action: actionSlice,
    loading: loadingSlice,
  },
});
