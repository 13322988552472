import { useMemo } from "react";
import { SemanticActionTypes } from ".";
import { Text } from "@chakra-ui/react";

interface DynamicTextProps {
  action: SemanticActionTypes;
  numEditedMessages: number;
  numEditedSenders: number;
}

export const DynamicText = ({
  action,
  numEditedMessages,
  numEditedSenders,
}: DynamicTextProps) => {
  const actionColor = useMemo(() => {
    switch (action) {
      case SemanticActionTypes.DELETE:
        return "#C53030";
      case SemanticActionTypes.LABEL:
        return;
      case SemanticActionTypes.UNSUB:
        return "#DD6B20";
    }
  }, [action]);

  const messagesString = useMemo(() => {
    if (action === SemanticActionTypes.UNSUB) return "";
    return "messages";
  }, [action]);

  const messageNum = useMemo(() => {
    if (action !== SemanticActionTypes.UNSUB) {
      return numEditedMessages;
    }
    return "";
  }, [action, numEditedMessages]);

  const plural = useMemo(() => {
    if (numEditedSenders > 1) {
      return "senders";
    }
    return "sender";
  }, [numEditedSenders]);

  return (
    <Text noOfLines={2} mb={4}>
      <strong style={{ color: actionColor }}>{action.toUpperCase()}</strong>{" "}
      {messageNum} {messagesString} from {numEditedSenders} {plural}
    </Text>
  );
};
