import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EmailLabel } from "../../lib/constants";

export interface ISender {
  categoryCounts: {
    [key: string]: number;
  };
  from: string;
  name: string;
  email: string;
  labelIds: string[];
  unsubscribeLink?: string;
  lastSendDate: number;
  selected: boolean;
  indeterminate: boolean;
  selectedLabels: string[];
  isUnsubscribed: boolean;
  hidden: boolean;
}
export interface IModifyLabelPayload {
  email: string;
  labelChanges: { id: string; count: number }[];
}
export interface ITrashSenderPayload {
  email: string;
  count: number;
}

const initialState: ISender[] = [];

const senderSlice = createSlice({
  name: "senders",
  initialState,
  reducers: {
    setSenders: (_, action: PayloadAction<ISender[]>) => {
      return action.payload.map((sender) => ({
        ...sender,
        selected: false,
        indeterminate: false,
        selectedLabels: [],
      }));
    },
    setFilteredSenders: (state, action: PayloadAction<ISender[]>) => {
      const senderEmails = action.payload.map((sender) => sender.email);
      return state.map((sender) => {
        if (senderEmails.includes(sender.email)) {
          return {
            ...sender,
            hidden: false,
          };
        } else
          return {
            ...sender,
            hidden: true,
          };
      });
    },
    selectSenders: (
      state,
      action: PayloadAction<{
        emails: string[];
        currentLabel: string;
      }>
    ) => {
      return state.map((sender) => {
        if (action.payload.emails.includes(sender.email)) {
          return {
            ...sender,
            selected: true,
            indeterminate: false,
            selectedLabels: Array.from(
              new Set([
                ...sender.selectedLabels,
                action.payload.currentLabel as string,
              ])
            ),
          };
        }
        return sender;
      });
    },
    deSelectSenders: (state, action: PayloadAction<string[]>) => {
      return state.map((sender) => {
        if (action.payload.includes(sender.email)) {
          return {
            ...sender,
            selected: false,
            indeterminate: false,
            selectedLabels: [],
          };
        }
        return sender;
      });
    },
    setSenderIndeterminate: (
      state,
      action: PayloadAction<{ email: string; indeterminate: boolean }>
    ) => {
      return state.map((sender) => {
        if (sender.email === action.payload.email) {
          return {
            ...sender,
            indeterminate: action.payload.indeterminate,
            selected: false,
          };
        }
        return sender;
      });
    },
    modifySenderLabels: (
      state,
      action: PayloadAction<IModifyLabelPayload[]>
    ) => {
      console.log({ payload: action.payload });
      return state.map((sender) => {
        const modifiedSender = action.payload.find(
          (modSender) => modSender.email === sender.email
        );
        if (modifiedSender) {
          // Create a copy of sender to avoid mutating the original state
          const newSender = {
            ...sender,
            categoryCounts: { ...sender.categoryCounts },
          };

          modifiedSender.labelChanges?.forEach((label) => {
            newSender.categoryCounts[label.id] =
              (newSender.categoryCounts[label.id] || 0) + label.count;
          });

          return newSender;
        }
        return sender;
      });
    },
    trashSenders: (state, action: PayloadAction<ITrashSenderPayload[]>) => {
      return state.map((sender) => {
        const trashSenderPayload = action.payload.find(
          (payload) => payload.email === sender.email
        );
        if (trashSenderPayload) {
          let newCategoryCounts = Object.keys(sender.categoryCounts).reduce(
            (acc, key) => {
              acc[key] = 0;
              return acc;
            },
            {} as ISender["categoryCounts"]
          );

          newCategoryCounts[EmailLabel.TRASH] = trashSenderPayload.count;
          return {
            ...sender,
            categoryCounts: newCategoryCounts,
          };
        }
        return sender;
      });
    },
    unsubscribeSenders: (state, action: PayloadAction<string[]>) => {
      return state.map((sender) => {
        if (action.payload.includes(sender.email)) {
          return {
            ...sender,
            isUnsubscribed: true,
          };
        }
        return sender;
      });
    },
  },
});

export const {
  setFilteredSenders,
  setSenders,
  selectSenders,
  deSelectSenders,
  setSenderIndeterminate,
  modifySenderLabels,
  trashSenders,
  unsubscribeSenders,
} = senderSlice.actions;

export default senderSlice.reducer;
