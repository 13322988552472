import { IClientUser } from "@/src/api/types";
import { sync } from "../../api/api";
import { useAuth } from "../../context/AuthContext";
import { EQueryKeys } from "../../lib/queryKeys";
import { useAppDispatch } from "../../store/provider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { User } from "firebase/auth";
import { useState } from "react";
import {
  IModifyLabelPayload,
  ISender,
  modifySenderLabels,
} from "../../store/slices/senderSlice";
import { ISyncMailboxResponse } from "@/functions/types";

export const usePartialSync = () => {
  const [needsFullSync, setNeedsFullSync] = useState(false);
  const dispatch = useAppDispatch();
  const { user, oauthToken, appCheckToken } = useAuth();
  const authObject = {
    ...user,
    oauthToken,
    appCheckToken,
  };
  const queryClient = useQueryClient();
  console.log("sync hook rendered");

  const { mutateAsync, isPending } = useMutation({
    mutationKey: [EQueryKeys.Sync],
    mutationFn: () =>
      sync({
        auth: authObject as IClientUser,
        historyId: queryClient.getQueryData([
          EQueryKeys.HistoryId,
          (user as User).uid,
        ])!,
      }),
    onSuccess: (apiResponse: ISyncMailboxResponse) => {
      console.log({ historyIdFromPartialSyncResponse: apiResponse.historyId });
      // get the negative values form the apiResponse and put those in the remove section of ImodifyLabelPayload. Put the positive values from the response in the add section of the IModifyLabelPayload.
      queryClient.setQueryData(
        [EQueryKeys.HistoryId, (user as User).uid],
        apiResponse.historyId
      );
      console.log("sync success");
      queryClient.setQueryData(
        [EQueryKeys.Senders, (user as User).uid],
        (oldSenders: ISender[]) => {
          const updatedSenders = oldSenders.map((sender) => {
            const senderToUpdate = Object.keys(apiResponse.labelsToChange).find(
              (from) => from === sender.from
            );
            if (senderToUpdate) {
              return {
                ...sender,
                categoryCounts: {
                  // sum the existing category counts from the sender with with the apiResonse category counts
                  ...Object.entries(sender.categoryCounts).reduce(
                    (acc, [labelId, count]) => {
                      return {
                        ...acc,
                        [labelId]:
                          count +
                          (apiResponse.labelsToChange[senderToUpdate][
                            labelId
                          ] ?? 0),
                      };
                    },
                    {}
                  ),
                },
              };
            }
            return sender;
          });
          return updatedSenders;
        }
      );
      const transaction: IModifyLabelPayload[] = Object.entries(
        apiResponse.labelsToChange
      ).map(([email, labelCount]) => {
        return {
          email,
          labelChanges: Object.entries(labelCount).map(([labelId, count]) => {
            return {
              id: labelId,
              count,
            };
          }),
        };
      });
      dispatch(modifySenderLabels(transaction));
    },
    onError: (error) => {
      setNeedsFullSync(true);
      console.log({ syncMailboxError: error });
    },
  });

  return {
    syncMailbox: mutateAsync,
    isSyncing: isPending,
    needsFullSync,
  };
};
