import { Icon, Text, Link } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const NavItem = ({
  icon,
  expanded,
  title,
  url,
  onClick,
}: {
  icon: any;
  expanded: boolean;
  title: string;
  url: string;
  onClick: () => void;
}) => {
  const hoverStyle = { color: "white" };
  const navigate = useNavigate();

  const handleClick = (url: string) => {
    navigate(url);
    onClick();
  };
  return (
    <Link
      onClick={() => handleClick(url)}
      mb="1"
      rounded="lg"
      p="2"
      role="group"
      _hover={hoverStyle}
      display="flex"
      alignItems="center"
      background={"transparent"}
    >
      <Icon
        color={"gray.300"}
        width={6}
        height={6}
        as={icon}
        _groupHover={hoverStyle}
      />
      {expanded && (
        <Text
          color={"gray.300"}
          fontSize="16"
          fontWeight="semibold"
          ml="3"
          _groupHover={hoverStyle}
        >
          {title}
        </Text>
      )}
    </Link>
  );
};
