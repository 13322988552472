import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyB2SoWSMmWkIRrt4R-BGh-Zo1Rlr8B-u10",
  authDomain: "app.lessmail.io",
  projectId: "email-client-358203",
  storageBucket: "email-client-358203.appspot.com",
  messagingSenderId: "904500017362",
  appId: "1:904500017362:web:044e83e16af0140448950b",
  measurementId: "G-B5E4J0ZY9T",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const functions = getFunctions(app, "us-central1");
export const db = getFirestore(app);

if (window.location.hostname === "localhost") {
  console.log("localhost");
  // @ts-ignore
  // eslint-disable-next-line no-restricted-globals
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  connectFunctionsEmulator(functions, "localhost", 5001);
  // connectFirestoreEmulator(db, "localhost", 8080);
}

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LfTwQ8lAAAAAB_pagp4GhIA7C8HlTlfswSz5mVf"),
  isTokenAutoRefreshEnabled: true,
});
