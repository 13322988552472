// https://tanstack.com/query/latest/docs/react/guides/optimistic-updates
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAppDispatch } from "../../store/provider";
import { postModifyLabels } from "../../api/api";
import { EQueryKeys } from "../../lib/queryKeys";
import {
  IModifyLabelPayload,
  ISender,
  modifySenderLabels,
} from "../../store/slices/senderSlice";
import { useAuth } from "../../context/AuthContext";
import { User } from "firebase/auth";
import { IClientUser } from "../../api/types";
import { ISelectedSender } from "../../lib/types";
import { EMailBoxFilters } from "../../store/slices/searchSlice";
import { getMessageIdsFromSender } from "../../utilities/helpers";
import { useSetCompletedAction } from "../../store/slices/actionSlice";
import { EActionTypes, ILabelResponse } from "@/functions/types";

const updateCategoryCounts = ({
  previousSender,
  numToAdd,
  labelsToAdd,
}: {
  previousSender: ISender;
  numToAdd: number;
  labelsToAdd: string[];
}) => {
  let updatedCounts = { ...previousSender.categoryCounts };
  labelsToAdd.forEach((labelId) => {
    updatedCounts[labelId] = updatedCounts[labelId] ?? 0 + numToAdd;
  });
  return updatedCounts;
};

export const useLabel = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const setCompletedAction = useSetCompletedAction();

  const { user, oauthToken, appCheckToken } = useAuth();
  const authObject = {
    ...user,
    oauthToken,
    appCheckToken,
  };

  const { mutateAsync: labelSelection, isPending: isLabelLoading } =
    useMutation({
      mutationKey: [EQueryKeys.ApplyLabels],
      mutationFn: ({
        senders,
        sendersWithMessagesSelected,
        labelsToAdd,
      }: {
        senders: ISender[];
        sendersWithMessagesSelected: ISelectedSender[];
        labelsToAdd: string[];
      }) =>
        postModifyLabels({
          auth: authObject as IClientUser,
          messageGroups: sendersWithMessagesSelected.reduce(
            (acc, selectedSender) => ({
              ...acc,
              [selectedSender.email]: {
                messageIds: getMessageIdsFromSender({
                  sender: senders.find(
                    (s) => s.email === selectedSender.email
                  )!,
                  selectedSender,
                }),
                selectedLabels:
                  selectedSender.selectedLabels.filter(
                    (l) =>
                      l !== EMailBoxFilters.ALL && l !== EMailBoxFilters.LISTS
                  ) ?? [],
              },
            }),
            {}
          ),
          labelsToAdd,
        }),
      onSuccess: (data: ILabelResponse, prev) => {
        setCompletedAction("LABEL" as EActionTypes.LABEL);
        // refetch messages for each of the senders in the selection
        prev.sendersWithMessagesSelected.forEach((selectedSender) => {
          return queryClient.refetchQueries({
            queryKey: [
              EQueryKeys.Messages,
              (user as User)?.uid,
              selectedSender.selectedLabels[0],
              selectedSender.email,
            ],
          });
        });
        // add labels to the senders in redux and query client cache
        queryClient.setQueryData<ISender[]>(
          [EQueryKeys.Senders, (user as User)?.uid],
          (old) => {
            return old!.map((sender) => {
              const selectedSender = prev.sendersWithMessagesSelected.find(
                (s) => s.email === sender.email
              );
              if (selectedSender) {
                return {
                  ...sender,
                  categoryCounts: updateCategoryCounts({
                    previousSender: sender,
                    numToAdd: data.messageGroups[sender.email].length,
                    labelsToAdd: data.labelsToAdd,
                  }),
                };
              }
              return sender;
            });
          }
        );
        // Transform messageGroups from the API into the format expected by the modifySenderLabels action.
        const updateLabelTransaction: IModifyLabelPayload[] = Object.entries(
          data.messageGroups
        ).map(([email, messageIds]) => ({
          email,
          labelChanges: data.labelsToAdd.map((labelId) => ({
            id: labelId,
            count: messageIds.length,
          })),
        }));
        dispatch(modifySenderLabels(updateLabelTransaction));

        // set the historyId in the query client cache
        queryClient.setQueryData<string>(
          [EQueryKeys.HistoryId, (user as User)?.uid],
          data.historyId
        );
      },
    });

  return { label: labelSelection, isLabelLoading };
};
