import { EActionTypes, IUndoResponse } from "@/functions/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { undo } from "../../api/api";
import { IClientUser } from "../../api/types";
import { EQueryKeys } from "../../lib/queryKeys";
import { useAppDispatch } from "../../store/provider";
import { useAuth } from "../../context/AuthContext";
import { User } from "@sentry/react";
import { ISender, setSenders } from "../../store/slices/senderSlice";
import { EmailLabel } from "../../lib/constants";

export const useUndo = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { user, oauthToken, appCheckToken } = useAuth();
  const authObject = {
    ...user,
    oauthToken,
    appCheckToken,
  };

  const commitQueryClientSenderUpdates = (apiResponse: IUndoResponse) => {
    const previousSenders = queryClient.getQueryData<ISender[]>([
      EQueryKeys.Senders,
      (user as User)?.uid,
    ]);
    let updatedSenders: ISender[] = [];
    switch (apiResponse.action) {
      case "ARCHIVE" as EActionTypes: {
        updatedSenders = previousSenders!.map((sender) => {
          const amountToAdd = apiResponse?.messageGroups[sender.email]?.length;
          if (amountToAdd) {
            return {
              ...sender,
              categoryCounts: {
                ...sender.categoryCounts,
                [EmailLabel.INBOX]:
                  sender.categoryCounts[EmailLabel.INBOX] + amountToAdd,
              },
            };
          }
          return sender;
        });
        break;
      }
      case "TRASH" as EActionTypes: {
        updatedSenders = previousSenders!.map((s) => {
          const addLabelTransaction = apiResponse.labelsAdded?.find(
            (trx) => trx.email === s.email
          );
          if (addLabelTransaction) {
            return {
              ...s,
              // add the counts that were removed back to the query client senders
              categoryCounts: {
                total:
                  s.categoryCounts.total +
                  apiResponse.messageGroups[s.email]?.length,
                ...Object.entries(s.categoryCounts).reduce(
                  (acc, [labelId, count]) => {
                    if (addLabelTransaction.labelsToChange[labelId]) {
                      acc[labelId] =
                        count + addLabelTransaction.labelsToChange[labelId];
                    } else {
                      acc[labelId] = count;
                    }
                    return acc;
                  },
                  {} as ISender["categoryCounts"]
                ),
              },
            };
          }
          return s;
        });
        break;
      }
      case "UNSUBSCRIBE" as EActionTypes: {
        updatedSenders = previousSenders!.map((s) => {
          if (apiResponse.emails.includes(s.email)) {
            return {
              ...s,
              isUnsubscribed: false,
            };
          }
          return s;
        });
        break;
      }
      case "LABEL" as EActionTypes: {
        updatedSenders = previousSenders!.map((s) => {
          const removeCount = apiResponse.messageGroups[s.email]?.length;
          const labelsToRemove = apiResponse.labelsRemoved;
          if (removeCount) {
            return {
              ...s,
              categoryCounts: {
                ...s.categoryCounts,
                ...Object.entries(s.categoryCounts).reduce(
                  (acc, [labelId, count]) => {
                    if ((labelsToRemove as string[])?.includes(labelId)) {
                      acc[labelId] = count - removeCount;
                    } else {
                      acc[labelId] = count;
                    }
                    return acc;
                  },
                  {} as ISender["categoryCounts"]
                ),
              },
            };
          }
          return s;
        });
      }
    }
    queryClient.setQueryData<ISender[]>(
      [EQueryKeys.Senders, (user as User)?.uid],
      updatedSenders
    );
    console.log({ updatedSenders });
    return updatedSenders;
  };

  const { mutateAsync: revertAction, isPending: isArchiveLoading } =
    useMutation({
      mutationKey: [EQueryKeys.UndoArchive],
      mutationFn: (historyId: string) =>
        undo({
          auth: authObject as IClientUser,
          historyId,
        }),
      onError: () => {
        alert(
          "Error undoing archive. This can happen when an email is already deleted."
        );
      },
      onSuccess: (data: IUndoResponse) => {
        const updatedSenders = commitQueryClientSenderUpdates(data);
        dispatch(setSenders(updatedSenders));
        queryClient.refetchQueries({
          queryKey: [EQueryKeys.History, (user as User)?.uid],
        });

        // set the historyId in the query client cache
        queryClient.setQueryData(
          [EQueryKeys.HistoryId, (user as User)?.uid],
          data.historyId
        );
      },
    });

  return { revertAction, isArchiveLoading };
};
