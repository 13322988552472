/* eslint-disable react-hooks/exhaustive-deps */
import { useInfiniteQuery } from "@tanstack/react-query";
import { useAuth } from "../../context/AuthContext";
import { useAppDispatch } from "../../store/provider";
import { getMessagesFromSender } from "../../api/api";
import { IClientUser } from "../../api/types";
import { User } from "@sentry/react";
import { EQueryKeys } from "../../lib/queryKeys";
import { useFilters } from "../../store/selectors";
import { useEffect } from "react";
import {
  IGmailMessage,
  addMessages,
  selectMessages,
} from "../../store/slices/messageSlice";
import { EMailBoxFilters } from "../../store/slices/searchSlice";

const getLabelIdForAPI = (labelId?: string) => {
  switch (labelId) {
    case EMailBoxFilters.ALL:
    case EMailBoxFilters.LISTS:
      return undefined;
    default:
      return labelId;
  }
};

export const useGetMessages = (email: string | null, selected?: boolean) => {
  const dispatch = useAppDispatch();
  const { user, oauthToken, appCheckToken } = useAuth();
  const authObject = {
    ...user,
    oauthToken,
    appCheckToken,
  };

  const { labelFilter } = useFilters();

  const {
    data: pageData,
    fetchNextPage,
    isFetching: isFetchingNextPage,
    isFetched,
    isLoading,
    hasNextPage,
  } = useInfiniteQuery({
    enabled: !!authObject && !!email,
    queryKey: [
      EQueryKeys.Messages,
      (user as User).uid,
      labelFilter.labelId,
      email,
    ],
    queryFn: (data) =>
      getMessagesFromSender({
        auth: authObject as IClientUser,
        pageParam: data?.pageParam,
        email: email!,
        labelId: getLabelIdForAPI(labelFilter.labelId),
      }),
    staleTime: Infinity,
    initialPageParam: null,
    getNextPageParam: (lastPage) => lastPage.next,
  });

  const fetchMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    const allMessages =
      pageData?.pages?.reduce((acc, page) => [...acc, ...page.messages], []) ??
      [];
    console.log({ pageData });
    dispatch(addMessages(allMessages));
    if (selected) {
      dispatch(selectMessages(allMessages.map((m: IGmailMessage) => m.id)));
    }
  }, [dispatch, pageData]);

  return {
    fetchNextPage: fetchMore,
    isLoading,
    isFetchingNextPage: isFetched && isFetchingNextPage && !isLoading, // isFetching is true when the query is fetching the next page or the query is fetched for the first time. Is loading is only true when the query is fetching the first page.
  };
};
