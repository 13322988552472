// https://tanstack.com/query/latest/docs/react/guides/optimistic-updates
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAppDispatch } from "../../store/provider";
import { postTrash } from "../../api/api";
import { EQueryKeys } from "../../lib/queryKeys";
import {
  IModifyLabelPayload,
  ISender,
  ITrashSenderPayload,
  deSelectSenders,
  modifySenderLabels,
  setSenders,
  trashSenders,
} from "../../store/slices/senderSlice";
import { EmailLabel } from "../../lib/constants";
import { useAuth } from "../../context/AuthContext";
import { User } from "firebase/auth";
import { IClientUser } from "../../api/types";
import {
  IGmailMessage,
  IModifyMessageLabelPayload,
  deselectMessages,
  modifyMessageLabels,
} from "../../store/slices/messageSlice";
import { ISelectedSender, PaginatedMessageQuery } from "../../lib/types";
import { EMailBoxFilters } from "../../store/slices/searchSlice";
import { getMessageIdsFromSender } from "../../utilities/helpers";
import { EActionTypes, ITrashResponse } from "@/functions/types";
import { useSetCompletedAction } from "../../store/slices/actionSlice";
import { useIsSelectionViewActive } from "../../context/SelectionViewContext";

const createModifyLabelPayload = (
  s: ISelectedSender,
  selectedMessages: IGmailMessage[]
) => {
  const updateCount = (
    payload: { id: string; count: number }[],
    id: string
  ) => {
    const index = payload.findIndex((a) => a.id === id);
    if (index === -1) {
      payload.push({ id, count: -1 });
    } else {
      payload[index].count -= 1;
    }
  };

  return {
    email: s.email,
    labelChanges: s.messageIds?.reduce(
      (payload: IModifyLabelPayload["labelChanges"], sender: string) => {
        updateCount(payload ?? [], "total");

        const message = selectedMessages.find((m) => m.id === sender)!;
        message?.labelIds?.forEach((labelId) => {
          updateCount(payload ?? [], labelId);
        });
        return payload;
      },
      [] as { id: string; count: number }[]
    ),
  };
};

export const useTrash = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const { setIsSelectionViewActive } = useIsSelectionViewActive();
  const setCompletedAction = useSetCompletedAction();

  const { user, oauthToken, appCheckToken } = useAuth();
  const authObject = {
    ...user,
    oauthToken,
    appCheckToken,
  };

  const optimisticallyUpdateSenders = ({
    sendersWithMessagesSelected,
    currentLabel,
    selectedMessages,
  }: {
    sendersWithMessagesSelected: ISelectedSender[];
    currentLabel: string;
    selectedMessages: IGmailMessage[];
  }) => {
    // Optimistically update the senders in redux
    const previousSenders = queryClient.getQueryData<ISender[]>([
      EQueryKeys.Senders,
      (user as User)?.uid,
    ]);

    const trashAllFromSenders: ITrashSenderPayload[] =
      sendersWithMessagesSelected
        .filter((s) => s.messageIds.length === 0) // Whole sender selected.
        .map((s) => ({ email: s.email, count: -s.count }));

    const trashSomeFromSenders: IModifyLabelPayload[] =
      sendersWithMessagesSelected
        .filter((s) => s.messageIds.length > 0) // Messages from a sender explicityly selected.
        .map((s) => createModifyLabelPayload(s, selectedMessages));

    if (
      currentLabel === EMailBoxFilters.ALL ||
      currentLabel === EMailBoxFilters.LISTS
    ) {
      dispatch(trashSenders(trashAllFromSenders));
      dispatch(modifySenderLabels(trashSomeFromSenders));
    } else {
      const trashAllInLabelFromSenders: IModifyLabelPayload[] =
        sendersWithMessagesSelected
          .filter((s) => s.messageIds.length === 0)
          .map((s) => ({
            email: s.email,
            labelChanges: [
              { id: currentLabel, count: -s.count },
              { id: "total", count: -s.count },
            ],
          }));
      dispatch(modifySenderLabels(trashAllInLabelFromSenders));
      dispatch(modifySenderLabels(trashSomeFromSenders));
    }
    return { previousSenders };
  };

  const optimisticallyUpdateMessages = (
    label: string,
    sendersWithMessagesSelected: ISelectedSender[],
    selectedMessageIds: string[]
  ) => {
    // Optimistically update the messages in redux
    const transaction: IModifyMessageLabelPayload[] = selectedMessageIds.map(
      (id) => {
        return {
          messageId: id,
          addLabelIds: [EmailLabel.TRASH],
        };
      }
    );
    dispatch(modifyMessageLabels(transaction));
    // invalidate queries to refetch messages for each of the senders in the selection.
    const previousMessageQueries = sendersWithMessagesSelected.forEach((s) => {
      queryClient.invalidateQueries({
        queryKey: [EQueryKeys.Messages, (user as User)?.uid, label, s.email],
      });
    });

    return { previousMessageQueries };
  };

  const rollbackMessageMutation = ({
    previousMessageQueries,
    label,
  }: {
    previousMessageQueries?: PaginatedMessageQuery[];
    label: string;
  }) => {
    if (previousMessageQueries) {
      previousMessageQueries.forEach((q) => {
        const messages = q.pages.flatMap((p) => p.messages);
        dispatch(
          modifyMessageLabels(
            messages.map((m) => ({
              messageId: m.id,
              removeLabelIds: [EmailLabel.TRASH],
            }))
          )
        );
        queryClient.invalidateQueries({
          queryKey: [
            EQueryKeys.Messages,
            (user as User)?.uid,
            label,
            messages[0].from,
          ],
        });
      });
    }
  };

  const rollbackSenderMutation = (previousSenders: ISender[]) => {
    queryClient.setQueryData<ISender[]>(
      [EQueryKeys.Senders, (user as User)?.uid],
      previousSenders
    );
    dispatch(setSenders(previousSenders));
  };

  const { mutateAsync: trashSelection, isPending: isTrashLoading } =
    useMutation({
      mutationKey: [EQueryKeys.Trash],
      mutationFn: ({
        sendersWithMessagesSelected,
        senders,
      }: {
        sendersWithMessagesSelected: ISelectedSender[];
        senders: ISender[];
      }) =>
        postTrash({
          auth: authObject as IClientUser,
          messageGroups: sendersWithMessagesSelected.reduce(
            (acc, selectedSender) => ({
              ...acc,
              [selectedSender.email]: {
                messageIds: getMessageIdsFromSender({
                  sender: senders.find(
                    (s) => s.email === selectedSender.email
                  )!,
                  selectedSender: selectedSender,
                }),
                selectedLabels:
                  selectedSender.selectedLabels.filter(
                    (l) =>
                      l !== EMailBoxFilters.ALL && l !== EMailBoxFilters.LISTS
                  ) ?? [],
              },
            }),
            {}
          ),
        }),
      onMutate: async ({
        sendersWithMessagesSelected,
        selectedMessages,
        label,
        senders,
      }: {
        sendersWithMessagesSelected: ISelectedSender[];
        selectedMessages: IGmailMessage[];
        label: string;
        senders: ISender[];
      }) => {
        const { previousSenders } = optimisticallyUpdateSenders({
          sendersWithMessagesSelected,
          currentLabel: label,
          selectedMessages,
        });
        const { previousMessageQueries } = optimisticallyUpdateMessages(
          label,
          sendersWithMessagesSelected,
          selectedMessages.map((m) => m.id)
        );

        const sendersToDeSelect = sendersWithMessagesSelected.map(
          (s) => s.email
        );
        dispatch(deSelectSenders(sendersToDeSelect));
        dispatch(
          deselectMessages(selectedMessages.map((m) => m.id) as string[])
        );

        setIsSelectionViewActive(false);
        setCompletedAction("TRASH" as EActionTypes.TRASH);

        return {
          previousSenders,
          previousMessageQueries,
          previousSendersWithMessagesSelected: sendersWithMessagesSelected,
          previousLabel: label,
        };
      },
      onError: (error, _, context) => {
        console.log({ error, context });
        if (context?.previousSenders)
          rollbackSenderMutation(context.previousSenders);

        if (
          context?.previousMessageQueries &&
          context?.previousSendersWithMessagesSelected
        )
          rollbackMessageMutation({
            previousMessageQueries:
              context.previousMessageQueries as PaginatedMessageQuery[],
            label: context.previousLabel,
          });
      },
      onSuccess: (data: ITrashResponse) => {
        console.log({ data });
        queryClient.setQueryData<ISender[]>(
          [EQueryKeys.Senders, (user as User)?.uid],
          (prev) => {
            return prev?.map((s) => {
              const removeTransaction = data.labelsToRemove.find(
                (trx) => trx.email === s.email
              );
              if (removeTransaction) {
                return {
                  ...s,
                  // reduce the counts in the sender category counts by the number specified in the transaction
                  categoryCounts: {
                    ...Object.entries(s.categoryCounts).reduce(
                      (acc, [labelId, count]) => {
                        if (removeTransaction.labelsToChange[labelId]) {
                          acc[labelId] =
                            count + removeTransaction.labelsToChange[labelId];
                        } else {
                          acc[labelId] = count;
                        }
                        return acc;
                      },
                      {} as ISender["categoryCounts"]
                    ),
                    total:
                      s.categoryCounts.total -
                      data.messageGroups[s.email].length,
                  },
                };
              }
              return s;
            });
          }
        );
        // set the historyId in the query client cache
        queryClient.setQueryData<string>(
          [EQueryKeys.HistoryId, (user as User)?.uid],
          data.historyId
        );
      },
    });

  return { trash: trashSelection, isTrashLoading };
};
