import { useQuery } from "@tanstack/react-query";
import { User } from "firebase/auth";
import { getFilters } from "../../api/api";
import { IClientUser } from "../../api/types";
import { EQueryKeys } from "../../lib/queryKeys";
import { useAuth } from "../../context/AuthContext";

export const useMailboxFilters = () => {
  const { user, oauthToken, appCheckToken } = useAuth();
  const authObject = {
    ...user,
    oauthToken,
    appCheckToken,
  };

  const { data: filters, isLoading: isFiltersLoading } = useQuery({
    enabled: !!user && !!oauthToken && !!appCheckToken,
    queryKey: [EQueryKeys.Filters, (user as User).uid],
    queryFn: () => getFilters({ auth: authObject as IClientUser }),
  });

  return { filters: filters ?? [], isFiltersLoading };
};
