import { Box, ButtonGroup, Flex, Text } from "@chakra-ui/react";
import { BiArchiveIn } from "react-icons/bi";
import { FiTrash, FiMicOff, FiTag } from "react-icons/fi";
import AnalyticsButton from "../ui/analyticsButton";
import {
  useLabelFilter,
  useSelectedMessageCount,
  useSelectedMessages,
  useSenders,
  useSendersWithMessagesSelected,
} from "../../store/selectors";
import { useArchive } from "../../hooks/mutations/useArchive";
import { useTrash } from "../../hooks/mutations/useTrash";
import { useUnsubscribe } from "../../hooks/mutations/useUnsubscribe";

export const BottomActionButtons = ({
  setLabelModalOpen,
}: {
  setLabelModalOpen: (open: boolean) => void;
}) => {
  const { archive, isArchiveLoading } = useArchive();
  const { trash, isTrashLoading } = useTrash();
  const { unsubscribe, isUnsubscribeLoading } = useUnsubscribe();
  const senders = useSenders();

  const labelFilter = useLabelFilter();
  const selectedMessageCount = useSelectedMessageCount();
  const sendersWithMessagesSelected = useSendersWithMessagesSelected();
  console.log({ sendersWithMessagesSelected });
  const selectedMessages = useSelectedMessages();

  const handleTrash = () => {
    if (isTrashLoading) {
      return;
    }
    trash({
      sendersWithMessagesSelected,
      selectedMessages,
      label: labelFilter.labelId,
      senders,
    });
  };

  const handleArchive = () => {
    if (isArchiveLoading) {
      return;
    }
    archive({
      senders,
      sendersWithMessagesSelected,
      selectedMessageIds: selectedMessages.map((m) => m.id),
    });
  };

  const handleUnsubscribe = () => {
    if (isUnsubscribeLoading) {
      return;
    }
    unsubscribe(sendersWithMessagesSelected.map((s) => s.email));
  };

  return (
    <ButtonGroup w={"full"}>
      <AnalyticsButton
        flex={1}
        loading={isTrashLoading}
        ariaLabel="trash"
        eventName="trash"
        metaData={{
          value: selectedMessageCount,
          numSenders: sendersWithMessagesSelected.length,
        }}
        onClick={handleTrash}
        color="white"
        h="unset"
        bg={{ base: "unset", md: "rgba(255, 255, 255, 0.08)" }}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="center"
          align="center"
          p={{ base: 0, md: 4 }}
        >
          <Box fontSize={{ base: "16px", md: "24px" }}>
            <FiTrash />
          </Box>
          <Text fontSize="sm" mt={{ base: 2, md: 0 }} ml={{ base: 0, md: 2 }}>
            Trash
          </Text>
        </Flex>
      </AnalyticsButton>
      <AnalyticsButton
        flex={1}
        loading={isUnsubscribeLoading}
        ariaLabel="unsubscribe"
        eventName="unsubscribe"
        metaData={{
          numSenders: sendersWithMessagesSelected.length,
        }}
        onClick={handleUnsubscribe}
        h="unset"
        bg={{ base: "unset", md: "rgba(255, 255, 255, 0.08)" }}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="center"
          align="center"
          p={{ base: 0, md: 4 }}
        >
          <Box fontSize={{ base: "16px", md: "24px" }}>
            <FiMicOff />
          </Box>
          <Text fontSize="sm" mt={{ base: 2, md: 0 }} ml={{ base: 0, md: 2 }}>
            Unsub
          </Text>
        </Flex>
      </AnalyticsButton>
      <AnalyticsButton
        flex={1}
        loading={isArchiveLoading}
        ariaLabel="archive"
        eventName="archive"
        metaData={{
          value: selectedMessageCount,
          numSenders: sendersWithMessagesSelected.length,
        }}
        onClick={handleArchive}
        h="unset"
        bg={{ base: "unset", md: "rgba(255, 255, 255, 0.08)" }}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="center"
          align="center"
          p={{ base: 0, md: 4 }}
        >
          <Box fontSize={{ base: "16px", md: "24px" }}>
            <BiArchiveIn />
          </Box>
          <Text fontSize="sm" mt={{ base: 2, md: 0 }} ml={{ base: 0, md: 2 }}>
            Archive
          </Text>
        </Flex>
      </AnalyticsButton>
      <AnalyticsButton
        flex={1}
        loading={false}
        ariaLabel="label"
        eventName="label"
        metaData={{
          value: selectedMessageCount,
          numSenders: sendersWithMessagesSelected.length,
        }}
        onClick={() => setLabelModalOpen(true)}
        h="unset"
        bg={{ base: "unset", md: "rgba(255, 255, 255, 0.08)" }}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="center"
          align="center"
          p={{ base: 2, md: 4 }}
        >
          <Box fontSize={{ base: "16px", md: "24px" }}>
            <FiTag />
          </Box>
          <Text fontSize="sm" mt={{ base: 2, md: 0 }} ml={{ base: 0, md: 2 }}>
            Label
          </Text>
        </Flex>
      </AnalyticsButton>
    </ButtonGroup>
  );
};
