import {
  Button,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  Modal,
  Input,
  Text,
} from "@chakra-ui/react";

import { useRef, useState } from "react";
import { useCreateLabel } from "../../hooks/mutations/useCreateLabel";

interface IAddLabelModalProps {
  open: boolean;
  onClose: () => void;
}

export const AddLabelModal = ({ open, onClose }: IAddLabelModalProps) => {
  const labelInputRef = useRef(null);
  const { createLabel, isCreateLabelLoading } = useCreateLabel();
  const [labelName, setLabelName] = useState<string>("");

  const handleConfirm = async () => {
    await createLabel(labelName);
    onClose();
  };

  return (
    <Modal
      size={{
        base: "full",
        md: "lg",
      }}
      isOpen={open}
      onClose={onClose}
      initialFocusRef={labelInputRef}
      isCentered
    >
      <ModalOverlay bg="rgba(0,0,0,.8)" />
      <ModalContent h="40vh">
        <ModalCloseButton />

        <ModalHeader>Add Label</ModalHeader>
        <ModalBody px={{ base: 5 }}>
          <Input
            onChange={(e) => setLabelName(e.target.value)}
            variant="filled"
          />
          <Text variant="secondary" fontSize="sm" mt={2}>
            To create a sub label, add the parent label name followed by a "/".
            E.g. "parent-label/child-label".
          </Text>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup spacing={2}>
            <Button onClick={onClose} variant="secondary">
              Cancel
            </Button>
            <Button
              isLoading={isCreateLabelLoading}
              onClick={handleConfirm}
              tabIndex={0}
            >
              Confirm
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
