import { Box, Button, Flex, Text, Tooltip } from "@chakra-ui/react";
import { BsInfoCircle } from "react-icons/bs";
import { useSidenavExpanded } from "../../context/SidenavContext";

export const UpgradeSection = () => {
  // const { showUpgrade } = useUserStripeInfo();
  // const { setIsPaywallOpen } = usePaywall();
  const showUpgrade = true;
  const setIsPaywallOpen = (open: boolean) => {};
  const { setSidenavExpanded } = useSidenavExpanded();

  const handleUpgradeOpen = () => {
    setIsPaywallOpen(true);
    setSidenavExpanded(false);
  };

  if (!showUpgrade) {
    return <Text my={4}>credits: unlimited</Text>;
  }

  return (
    <Flex my={4} direction="column" bg="gray.700" p={4} rounded="lg">
      <Flex justify="space-between" align="center">
        <Text>Lessmail Lite</Text>
        <Tooltip
          rounded="lg"
          p={4}
          bg="gray.900"
          label="With lessmail lite you can use all Lessmail features for a limited time. Once you run out of free unsubscribes, you can click here or on the popup to purchase credits."
        >
          <Box>
            <BsInfoCircle />
          </Box>
        </Tooltip>
      </Flex>
      <Button onClick={handleUpgradeOpen} mt={2}>
        Upgrade Account
      </Button>
    </Flex>
  );
};
