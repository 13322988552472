import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
// import { createLocalForagePersister } from "./storage";
import { createAsyncStoragePersister } from "@tanstack/query-async-storage-persister";
import localforage from "localforage";
import { StoreProvider } from "./store/provider";
import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import { Global } from "@emotion/react";
import { MessageViewProvider } from "./context/MessageViewContext";
import { EQueryKeys } from "./lib/queryKeys";
import { SidenavProvider } from "./context/SidenavContext";

mixpanel.init("b08abc7e00a856b11b42545bf07cd651");

// eslint-disable-next-line no-restricted-globals
if (location.hostname !== "127.0.0.1" && location.hostname !== "localhost") {
  Sentry.init({
    dsn: "https://4850b435787740b292ec42f88b2ea27d@o4504358843842560.ingest.sentry.io/4504358845480960",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.6,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: Infinity,
      staleTime: 1000 * 10, // 30 seconds
    },
  },
});

root.render(
  <React.Fragment>
    <Global
      styles={`@font-face {
          font-family: "HKGroteskPro";
          font-weight: 400;
          src: url("/fonts/HKGroteskPro-Regular.woff2") format("woff2"), 
          url("/fonts/HKGroteskPro-Regular.woff") format("woff");
        }

        @font-face {
          font-family: "HKGroteskPro";
          font-weight: 600;
          src: url("/fonts/HKGroteskPro-Medium.woff2") format("woff2"), 
          url("/fonts/HKGroteskPro-Medium.woff2") format("woff");
        }

        @font-face {
          font-family: "HKGroteskPro";
          font-weight: 700;
          src: url("/fonts/HKGroteskPro-Bold.woff2") format("woff2"),
          url("/fonts/HKGroteskPro-Bold.woff") format("woff");
        }
        p {
          margin: 0;
        }

        .chakra-input {
          background: #2D3748 !important;
        }

        .chakra-popover__content {
          background: #1A202C !important
        }

        .chakra-ui-light .css-19rxthz:not([data-theme]),[data-theme=light] .css-19rxthz:not([data-theme]),.css-19rxthz[data-theme=light]{background:var(--chakra-colors-blackAlpha-800) !important;}

        ::-webkit-scrollbar { width: 3px !important; }
        ::-webkit-scrollbar-track { background: #2D3748 !important; }
        ::-webkit-scrollbar-thumb { background: #A0AEC0 !important; }
      
        .js-focus-visible :focus:not([data-focus-visible-added]) {
          outline: none;
          box-shadow: none;
        }

        .chakra-wrap {
          background: unset !important;
          border-raduis: 10px !important;
          border-color: #2D3748 !important;
        }

        .sender-card-text {
          -webkit-touch-callout: none; /* Safari */
          -webkit-user-select: none; /* Chrome */     
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; 
        }
      `}
    />
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister: createAsyncStoragePersister({
          storage: localforage,
        }),
        maxAge: Infinity,
        dehydrateOptions: {
          shouldDehydrateQuery: (query) => {
            if (query.queryKey[0] === EQueryKeys.Messages) {
              return false;
            }
            if (query.queryKey[0] === EQueryKeys.History) {
              return false;
            }
            return true;
          },
        },
      }}
    >
      <StoreProvider>
        <MessageViewProvider>
          <SidenavProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </SidenavProvider>
        </MessageViewProvider>
      </StoreProvider>
    </PersistQueryClientProvider>
  </React.Fragment>
);
