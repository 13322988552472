import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  List,
  ListItem,
} from "@chakra-ui/react";
import { useSort } from "../../store/selectors";
import { ESortOptions, setSort } from "../../store/slices/sortSlice";
import { useAppDispatch } from "../../store/provider";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SortModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const sort = useSort();

  const options: ESortOptions[] = [
    ESortOptions.MessageNum,
    ESortOptions.Latest,
    ESortOptions.Oldest,
  ];

  const handleOptionClick = (option: ESortOptions) => {
    dispatch(setSort(option));
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="bottom">
      <DrawerOverlay />
      <DrawerContent bg="unset">
        <DrawerCloseButton />
        <DrawerHeader>Sort By</DrawerHeader>
        <DrawerBody pt={4}>
          <List spacing={1}>
            {options.map((key) => (
              <ListItem
                px={3}
                borderRadius="md"
                bg={sort === key ? "primary.500" : "unset"}
                key={key}
                py={2}
                onClick={() => handleOptionClick(key)}
              >
                {key}
              </ListItem>
            ))}
          </List>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default SortModal;
