import History from "./screens/history";
import Support from "./screens/support";
import AccountSettings from "./screens/account-settings";
import Dashboard from "./screens/dashboard";
import Login from "./screens/login";
import PaymentSuccess from "./screens/payment-success";
import PaymentCancellation from "./screens/payment-cancellation";
import { Navigate, useLocation } from "react-router-dom";
// import { useAuth } from "./context/AuthProvider";

const routes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Dashboard />,
    requireAuth: true,
  },
  {
    path: "/history",
    element: <History />,
    requireAuth: true,
  },
  {
    path: "/payment-success",
    element: <PaymentSuccess />,
    requireAuth: true,
  },
  {
    path: "/payment-cancellation",
    element: <PaymentCancellation />,
    requireAuth: true,
  },
  {
    path: "/account-settings",
    element: <AccountSettings />,
    requireAuth: true,
  },
  {
    path: "/support",
    element: <Support />,
  },
];

export function RequireAuth({ children, user }: any) {
  let location = useLocation();
  if (!user) return <Navigate to="/login" state={{ from: location }} replace />;
  return children;
}

export function LoginRouter({ children, user }: any) {
  if (user) return <Navigate to="/" />;
  return children;
}

export default routes;
