import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { LabelSection } from "./labelSection";
import { MailBoxSection } from "./mailBoxSection";
import { UpgradeSection } from "./upgradeSection";
import { UserInfo } from "./userInfoCard";
import { useSidenavExpanded } from "../../context/SidenavContext";

const DesktopSideNav = () => {
  return (
    <Flex overflowY="scroll" direction="column" w="300px" bg="gray.900" p={6}>
      <UserInfo />
      <UpgradeSection />
      <MailBoxSection />
      <LabelSection />
    </Flex>
  );
};

const MobileSideNav = () => {
  const { sidenavExpanded, setSidenavExpanded } = useSidenavExpanded();

  return (
    <Drawer
      isOpen={sidenavExpanded}
      placement="left"
      onClose={() => setSidenavExpanded(false)}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader pb={2} bg="primary.700">
          <Heading fontSize="sm">Lessmail.io</Heading>
          <DrawerCloseButton />
        </DrawerHeader>
        <DrawerBody pt={6}>
          <UserInfo />
          <UpgradeSection />
          <MailBoxSection />
          <LabelSection />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export const SideNav = () => {
  const isMobile = window.innerWidth < 768;

  if (isMobile) {
    return <MobileSideNav />;
  } else {
    return <DesktopSideNav />;
  }
};
