import { Menu, MenuItem, MenuList } from "@chakra-ui/react";
import { FiMousePointer, FiSearch } from "react-icons/fi";
import { useAllSenders } from "../../store/selectors";
import { useSearchFilters } from "../../hooks/store/useSearchFilters";
import { useAppDispatch } from "../../store/provider";
import { selectSenders } from "../../store/slices/senderSlice";
import { EMailBoxFilters } from "../../store/slices/searchSlice";

interface ContextMenuProps {
  email: string;
  isOpen: boolean;
  onClose: () => void;
}

export function ContextMenu({ email, isOpen, onClose }: ContextMenuProps) {
  const dispatch = useAppDispatch();
  const { onSearch } = useSearchFilters();
  const senders = useAllSenders();

  const domainParts = email?.split("@")[1].split(".") ?? "";
  const lastDomainPart = domainParts[domainParts?.length - 2] ?? "";

  const handleFindAll = (e: any) => {
    e.stopPropagation();
    onSearch(lastDomainPart);
    onClose();
  };

  const handleSelectAllFromDomain = (e: any) => {
    e.stopPropagation();
    const allSendersFromDomain = senders
      .filter((sender) => sender.email?.includes(lastDomainPart))
      .map((sender) => sender.email);
    dispatch(
      selectSenders({
        emails: allSendersFromDomain,
        currentLabel: EMailBoxFilters.ALL,
      })
    );
    onClose();
  };

  return (
    <div style={{ position: "absolute", marginTop: 48 }}>
      <Menu isOpen={isOpen}>
        <MenuList bg="gray.800">
          <MenuItem
            _hover={{
              bg: "gray.700",
            }}
            bg="gray.800"
            icon={<FiSearch />}
            onClick={handleFindAll}
          >
            Find all from {lastDomainPart}
          </MenuItem>
          <MenuItem
            _hover={{
              bg: "gray.700",
            }}
            bg="gray.800"
            icon={<FiMousePointer />}
            onClick={handleSelectAllFromDomain}
          >
            Select all from {lastDomainPart}
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
}
