import {
  GoogleAuthProvider,
  User,
  getRedirectResult,
  onAuthStateChanged,
  signInWithRedirect,
} from "firebase/auth";
import { appCheck, auth } from "../lib/firebaseConfig";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { IClientUser } from "../api/types";
import { getToken } from "firebase/app-check";
import { useIsRestoring, useQueryClient } from "@tanstack/react-query";
import { EQueryKeys } from "../lib/queryKeys";

interface IAuthContextProps {
  loading: boolean;
  user: User | null | false;
  oauthToken?: string | null;
  appCheckToken?: string | null;
  signin: () => Promise<void>;
  signout: () => Promise<void>;
}

// Google OAuth provider with gmail permission scopes setup.
const googleAuth = new GoogleAuthProvider();
googleAuth.addScope("https://www.googleapis.com/auth/gmail.modify");
googleAuth.addScope("https://www.googleapis.com/auth/gmail.settings.basic");
googleAuth.setCustomParameters({
  prompt: "select_account",
});

const AuthContext = createContext<IAuthContextProps>({
  loading: true,
  user: null,
  oauthToken: null,
  appCheckToken: null,
  signin: async () => {},
  signout: async () => {},
});

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const restoring = useIsRestoring();
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IClientUser | null | false>(null);
  const [appCheckToken, setAppCheckToken] = useState<string | null>(
    queryClient.getQueryData([EQueryKeys.AppcheckToken]) ?? null
  );
  const [oauthToken, setOauthToken] = useState<string | null>(
    queryClient.getQueryData([EQueryKeys.OauthToken]) ?? null
  );
  console.log(oauthToken);

  const getAppCheckToken = async () => {
    if (appCheckToken) {
      setLoading(false);
      return;
    }
    const { token: deviceToken } = await getToken(appCheck);
    queryClient.setQueryData([EQueryKeys.AppcheckToken], deviceToken);
    setAppCheckToken(deviceToken);
  };

  // Listens to auth state and gets appCheckToken for each unique device
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        setUser(false);
        return;
      } else setUser(user);
    });
    getAppCheckToken();
    //  signout();
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Sets the oauthToken from the google sign in redirect result
  useEffect(() => {
    console.log({ restoring });
    if (restoring) return;
    getRedirectResult(auth)
      .then(async (result) => {
        console.log({ result });

        if (!result) {
          console.log("no result");
          checkExpiration();
          setLoading(false);
          return;
        }
        const { oauthAccessToken, oauthExpireIn } = (result as any)
          ._tokenResponse;
        const user = result.user;

        const expirationDateTime = getOauthExpiry(oauthExpireIn);
        queryClient.setQueryData([EQueryKeys.OauthToken], oauthAccessToken);
        queryClient.setQueryData([EQueryKeys.OauthExpiry], expirationDateTime);

        setOauthToken(oauthAccessToken);
        setUser(user);
        setLoading(false);
      })
      .catch((error) => {
        checkExpiration();
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restoring]);

  async function signin() {
    signInWithRedirect(auth, googleAuth);
  }

  async function checkExpiration() {
    const expirationDateTime = queryClient.getQueryData([
      EQueryKeys.OauthExpiry,
    ]) as number;

    if (!expirationDateTime || new Date().getTime() > expirationDateTime) {
      signout();
    } else {
      const token = queryClient.getQueryData([EQueryKeys.OauthToken]);
      if (!token) {
        signout();
        return;
      }
      setOauthToken(token as string);
    }
  }

  function getOauthExpiry(expiresIn: number) {
    const t = new Date();
    const expirationDateTime = t.setSeconds(t.getSeconds() + expiresIn - 10); // 10 second buffer before expiry
    return expirationDateTime;
  }

  async function signout() {
    await auth.signOut();
    queryClient.setQueryData([EQueryKeys.OauthToken], null);
    queryClient.setQueryData([EQueryKeys.OauthExpiry], null);
    setUser(false);
    setOauthToken(null);
  }

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        oauthToken,
        appCheckToken,
        signin,
        signout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
