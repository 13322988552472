import React, { useState } from "react";
import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Container,
  SimpleGrid,
  Avatar,
  AvatarGroup,
  useBreakpointValue,
  Link,
  Button,
} from "@chakra-ui/react";
import { BetaModal } from "../components/modals/betaModal";
import ImageButton from "../components/ui/imageButton";
import outlook from "../assets/icons/outlook.svg";
import yahoo from "../assets/icons/yahoo.svg";
import google from "../assets/icons/google.svg";
import aol from "../assets/icons/aol.png";
import mixpanel from "mixpanel-browser";
import { useAuth } from "../context/AuthContext";
import { Blur } from "../components/ui/blur";

const avatars = [
  {
    name: "Ryan Florence",
    url: "https://bit.ly/ryan-florence",
  },
  {
    name: "Segun Adebayo",
    url: "https://bit.ly/sage-adebayo",
  },
  {
    name: "Kent Dodds",
    url: "https://bit.ly/kent-c-dodds",
  },
  {
    name: "Prosper Otemuyiwa",
    url: "https://bit.ly/prosper-baba",
  },
  {
    name: "Christian Nwamba",
    url: "https://bit.ly/code-beast",
  },
];

export default function Login() {
  const { signin } = useAuth();
  const avatarSize = useBreakpointValue({ base: "md", md: "lg" });
  const [showBetaModal, setShowBetaModal] = useState(false);
  const [provider, setProvider] = useState<string | null>(null);

  const handleSignIn = async (provider: string) => {
    mixpanel.track(`sign_in_with_${provider.toLowerCase()}`);
    if (provider.toLowerCase() === "google") signin();
    else {
      setProvider(provider);
      setShowBetaModal(true);
    }
  };

  return (
    <Box
      position="relative"
      w="full"
      minH={{ base: "100vh", md: "100vh" }}
      overflowY={{ base: "scroll", lg: "unset" }}
    >
      <BetaModal
        open={showBetaModal}
        onClose={() => setShowBetaModal(false)}
        provider={provider!}
      />
      <Container
        minW="full"
        as={SimpleGrid}
        mt={{ base: 0, lg: 15 }}
        columns={{ base: 1, xl: 2 }}
        spacing={{ base: 5, lg: 32 }}
        p={{ base: 0, lg: 28 }}
        display={{ base: "flex", sm: "grid" }}
        flexDirection={{ base: "column", sm: "unset" }}
        overflowY={{ base: "scroll" }}
      >
        <Stack
          p={8}
          zIndex={1}
          spacing={{ base: 10, md: 20 }}
          mb={{ base: 0, sm: "unset" }}
        >
          <Heading
            zIndex={100}
            lineHeight={{ base: "1.4", md: "1.2" }}
            color="white"
            fontSize={{ base: "4xl", sm: "4xl", md: "5xl", lg: "6xl" }}
          >
            Join Lessmail Today
            <Text
              px="2"
              as={"span"}
              bgGradient="linear(to-r, green.400, blue.400)"
              bgClip="text"
            >
              &
            </Text>
            Clean Your Email in Minutes.
          </Heading>
          <Stack
            direction={"row"}
            spacing={2}
            align={"center"}
            mt={{ base: "4 !important", md: 12 }}
          >
            <AvatarGroup>
              {avatars.map((avatar) => (
                <Avatar
                  key={avatar.name}
                  name={avatar.name}
                  src={avatar.url}
                  size={avatarSize}
                  position={"relative"}
                  zIndex={2}
                  _before={{
                    content: '""',
                    width: "full",
                    height: "full",
                    rounded: "full",
                    transform: "scale(1.125)",
                    bgGradient: "linear(to-bl, green.400,blue.400)",
                    position: "absolute",
                    zIndex: -1,
                    top: 0,
                    left: 0,
                  }}
                />
              ))}
            </AvatarGroup>
            <Text
              fontFamily={"heading"}
              color="white"
              fontSize={{ base: "4xl", md: "6xl" }}
            >
              +
            </Text>
            <Flex
              align={"center"}
              justify={"center"}
              fontFamily={"heading"}
              fontSize={{ base: "sm", md: "lg" }}
              fontWeight="600"
              bg={"gray.300"}
              color={"gray.900"}
              rounded={"full"}
              width={useBreakpointValue({ base: "44px", md: "60px" })}
              height={useBreakpointValue({ base: "44px", md: "60px" })}
              position={"relative"}
              _before={{
                content: '""',
                width: "full",
                height: "full",
                rounded: "full",
                transform: "scale(1.125)",
                bgGradient: "linear(to-bl, orange.400,yellow.400)",
                position: "absolute",
                zIndex: -1,
                top: 0,
                left: 0,
              }}
            >
              YOU
            </Flex>
          </Stack>
        </Stack>
        <Stack
          bg="primary.600"
          zIndex={1}
          rounded={"xl"}
          borderBottomRadius={{ base: "none", md: "xl" }}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: "auto" }}
          flex={{ base: 1, md: "unset" }}
        >
          <Stack spacing={4}>
            <Heading
              as={"span"}
              bg="white"
              bgClip="text"
              transform="skew(-4deg)"
              lineHeight={1.1}
              fontSize={{ base: "32px", sm: "3xl", md: "4xl" }}
              mb={4}
            >
              Connect
              <Text as={"span"} bgClip="text" transform="skew(30deg)">
                !
              </Text>
            </Heading>
            <Box>
              <ImageButton
                alt="Sign in with Google"
                src={google}
                onClick={() => handleSignIn("Google")}
              >
                Sign in with Google
              </ImageButton>
            </Box>
            <Box>
              <ImageButton
                alt="Sign in with Outlook"
                src={outlook}
                onClick={() => handleSignIn("Microsoft")}
              >
                Outlook, Hotmail, Office 365
              </ImageButton>
            </Box>
            <Box>
              <ImageButton
                alt="Sign in with Yahoo"
                src={yahoo}
                onClick={() => handleSignIn("Yahoo")}
              >
                Yahoo Mail
              </ImageButton>
            </Box>
            <Box>
              <ImageButton
                alt="Sign in with AOL"
                src={aol}
                onClick={() => handleSignIn("Aol")}
              >
                Sign in with AOL
              </ImageButton>
            </Box>
            <Flex align="center">
              <span
                style={{ background: "lightgray", height: 1, width: "49%" }}
              />
              <Text m={4}>OR</Text>
              <span
                style={{ background: "lightgray", height: 1, width: "48%" }}
              />
            </Flex>
            <Box>
              <Button
                w="full"
                h="48px"
                mb={4}
                onClick={() => handleSignIn("imap")}
              >
                Sign in with IMAP
              </Button>
            </Box>
          </Stack>
          <Text
            mt="auto"
            variant="secondary"
            fontSize={{ base: "xs", sm: "md" }}
          >
            Lessmail never stores any of your email contents and does not sell
            user data to third parties, learn more in our{" "}
            <Link
              href="https://lessmail.io/privacy.html"
              textDecoration="underline !important"
            >
              Privacy Policy.
            </Link>
          </Text>
        </Stack>
      </Container>
      <Blur
        opacity={0.8}
        zIndex={0}
        position={"absolute"}
        top={{ base: "unset", md: -10 }}
        bottom={{ base: -10, md: "unset" }}
        left={-10}
        style={{ filter: "blur(70px)" }}
      />
    </Box>
  );
}
