import { ISender, setFilteredSenders } from "../../store/slices/senderSlice";
import {
  EFilterTypes,
  EMailBoxFilters,
  ILabelFilter,
  updateLabelFilter,
  updateSearchFilter,
} from "../../store/slices/searchSlice";
import Fuse from "fuse.js";
import { useAppDispatch } from "../../store/provider";
import {
  useAllSenders,
  useLabelFilter,
  useSearch,
} from "../../store/selectors";
import { useMailboxLabels } from "../queries/useMailboxLabels";

// FILTER FUNCTIONS
export const _filterBySearch = ({
  senders,
  search,
  labelFilter,
}: {
  senders: ISender[];
  search: string;
  labelFilter: ILabelFilter;
}) => {
  if (!search) {
    return senders;
  }
  const searchArray = search.split(",");

  // Create the Fuse instance once
  const fuse = new Fuse(senders, {
    keys: ["email", "name"],
    threshold: 0.2,
  });

  // Search for all terms and reduce into a single array
  const results = searchArray
    .map((searchTerm) => fuse.search(searchTerm).map((r) => r.item))
    .reduce((acc, res) => [...acc, ...res], []);
  console.log("test", labelFilter.labelId, EMailBoxFilters.LISTS);

  if (labelFilter.labelId === EMailBoxFilters.LISTS) {
    // If the label filter is lists, then we need to filter out any senders that do not have an unsubscribe link
    return results.filter((sender) => {
      return sender.unsubscribeLink;
    });
  }
  if (labelFilter.labelId === EMailBoxFilters.ALL) {
    return results;
  }
  // additionally filter out any senders that have 0 category counts for the current label filter
  const finalResults = results.filter((sender) => {
    return sender.categoryCounts[labelFilter.labelId] > 0;
  });
  return finalResults;
};

export const _filterByLabel = (
  senders: ISender[],
  labelFilter: ILabelFilter
) => {
  let newFilteredSenders: ISender[] = [];

  if (labelFilter.labelId) {
    switch (labelFilter.labelId) {
      case EMailBoxFilters.LISTS:
        newFilteredSenders = senders.filter((sender) => sender.unsubscribeLink);
        break;
      case EMailBoxFilters.ALL:
        newFilteredSenders = senders;
        break;
      default:
        newFilteredSenders = senders.filter((sender) => {
          return sender.categoryCounts[labelFilter.labelId] > 0;
        });
    }
    if (labelFilter.and.length > 0) {
      newFilteredSenders = newFilteredSenders.filter((sender) => {
        return labelFilter.and.every((label) =>
          sender.labelIds?.includes(label)
        );
      });
    }
  }
  return newFilteredSenders;
};

export const useSearchFilters = () => {
  const dispatch = useAppDispatch();

  const labelFilter = useLabelFilter();
  const senders = useAllSenders();
  const search = useSearch();
  const { labels } = useMailboxLabels();

  const clearSearch = () => {
    if (labelFilter.labelId === EMailBoxFilters.LISTS) {
      const newFilteredSenders = senders.filter((s) => s.unsubscribeLink);
      dispatch(setFilteredSenders(newFilteredSenders));
    } else {
      const newFilteredSenders = _filterByLabel(senders, labelFilter);
      dispatch(setFilteredSenders(newFilteredSenders));
    }
    dispatch(updateSearchFilter(""));
  };

  const onSearch = (searchTerm: string) => {
    if (searchTerm) {
      const filteredSenders = _filterByLabel(senders, labelFilter);
      const newFilteredSenders = _filterBySearch({
        senders: filteredSenders,
        search: searchTerm,
        labelFilter,
      });
      dispatch(setFilteredSenders(newFilteredSenders));
      dispatch(updateSearchFilter(searchTerm));
    } else {
      clearSearch();
    }
  };

  // TODO: Clean function up
  const onLabelPress = ({
    id,
    filterType,
  }: {
    id: string;
    filterType: EFilterTypes;
  }) => {
    if (filterType === EFilterTypes.LabelFilter) {
      const labelName = labels?.find((l) => l.id === id)?.name ?? "";
      const newLabelFilter = {
        ...labelFilter,
        labelId: id,
        labelName,
      };
      let newFilteredSenders = _filterByLabel(senders, newLabelFilter);
      console.log({ newFilteredSenders });

      // Apply search filter if present
      if (search) {
        newFilteredSenders = _filterBySearch({
          senders: newFilteredSenders,
          search,
          labelFilter: newLabelFilter,
        });
      }

      dispatch(setFilteredSenders(newFilteredSenders));
      dispatch(updateLabelFilter(newLabelFilter));
    } else if (filterType === EFilterTypes.UnsubscribeLink) {
      let newFilteredSenders = senders.filter((s) => s.unsubscribeLink);
      console.log({ newFilteredSenders });

      // Apply search filter if present
      if (search) {
        newFilteredSenders = _filterBySearch({
          senders: newFilteredSenders,
          search,
          labelFilter,
        });
      }
      dispatch(
        updateLabelFilter({
          labelId: EMailBoxFilters.LISTS,
          labelName: EMailBoxFilters.LISTS,
          and: [],
        })
      );
      dispatch(setFilteredSenders(newFilteredSenders));
    }
  };

  return {
    labelFilter,
    search,
    clearSearch,
    onSearch,
    onLabelPress,
  };
};
