import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Heading,
  AccordionIcon,
  AccordionPanel,
  Flex,
  Button,
  Text,
} from "@chakra-ui/react";
import { BsCircle, BsMailbox, BsPersonCheck, BsStar } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { VscMegaphone } from "react-icons/vsc";
import { MdLabelImportantOutline, MdUpdate } from "react-icons/md";
import { SlPeople } from "react-icons/sl";
import { TbMessages } from "react-icons/tb";
import { AiOutlineTags } from "react-icons/ai";
import { FiTag } from "react-icons/fi";
import { EFilterTypes, EMailBoxFilters } from "../../store/slices/searchSlice";
import { ECategories } from "../../lib/constants";
import { IconType } from "react-icons";
import { useFilters } from "../../store/selectors";
import { useSidenavExpanded } from "../../context/SidenavContext";
import { useMessageView } from "../../context/MessageViewContext";
import { useSearchFilters } from "../../hooks/store/useSearchFilters";
import { useAppSelector } from "../../store/provider";
import { getCategoryKey } from "../../utilities/helpers";

export type TDefaultFilters = ECategories | EMailBoxFilters;
export interface IFilterValue {
  icon: IconType;
  type: EFilterTypes;
}
export const MailBoxFilters: Record<EMailBoxFilters, IFilterValue> = {
  // [EMailBoxFilters.SENT]: BsSend,
  [EMailBoxFilters.INBOX]: {
    icon: BsMailbox,
    type: EFilterTypes.LabelFilter,
  },
  [EMailBoxFilters.LISTS]: {
    icon: VscMegaphone,
    type: EFilterTypes.UnsubscribeLink,
  },
  [EMailBoxFilters.IMPORTANT]: {
    icon: MdLabelImportantOutline,
    type: EFilterTypes.LabelFilter,
  },
  [EMailBoxFilters.STARRED]: {
    icon: BsStar,
    type: EFilterTypes.LabelFilter,
  },
  // [EMailBoxFilters.DRAFT]: {
  //   icon: RiDraftLine,
  //   type: EFilterTypes.LabelFilter,
  // },
  // [EMailBoxFilters.TRASH]: BsTrash,
  // [EMailBoxFilters.SPAM]: RiFolderWarningLine,
  [EMailBoxFilters.ALL]: {
    icon: BsCircle,
    type: EFilterTypes.LabelFilter,
  },
};

const CategoryFilters: Record<ECategories, IFilterValue> = {
  [ECategories.SOCIAL]: {
    icon: SlPeople,
    type: EFilterTypes.LabelFilter,
  },
  [ECategories.UPDATES]: {
    icon: MdUpdate,
    type: EFilterTypes.LabelFilter,
  },
  [ECategories.FORUMS]: {
    icon: TbMessages,
    type: EFilterTypes.LabelFilter,
  },
  [ECategories.PROMOTIONS]: {
    icon: AiOutlineTags,
    type: EFilterTypes.LabelFilter,
  },
  [ECategories.PERSONAL]: {
    icon: BsPersonCheck,
    type: EFilterTypes.LabelFilter,
  },
};

export const MailBoxSection = () => {
  const { labelFilter } = useFilters();
  const { onLabelPress } = useSearchFilters();
  const { setSidenavExpanded } = useSidenavExpanded();
  const { isMessageViewOpen, focusedSender } = useMessageView();
  //  const selectedSenders = useSelectedSenders();

  const location = useLocation();
  const navigate = useNavigate();

  const isMobile = window.innerWidth < 768;

  const handleClick = (id: string, filterType: EFilterTypes) => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    if (isMessageViewOpen && focusedSender) {
      // getMessages({
      //   email: focusedSender,
      //   defaultSelected: selectedSenders.includes(focusedSender),
      //   labelId: id,
      // });
    }
    onLabelPress({
      id,
      filterType,
    });
    if (isMobile) {
      setSidenavExpanded(false);
    }
  };

  const isCategorySelected = labelFilter.labelName.includes("CATEGORY");

  const CategorySection = () => {
    return (
      <Accordion
        defaultIndex={isCategorySelected ? 0 : undefined}
        w="full"
        allowToggle
      >
        <AccordionItem
          _hover={{
            bg: "transparent",
          }}
          _last={{
            borderBottomWidth: 0,
          }}
          borderBottomWidth={0}
          borderTopWidth={0}
        >
          <h2>
            <AccordionButton
              _hover={{
                bg: "transparent",
              }}
              p={0}
              display="flex"
              flex={1}
              justifyContent="space-between"
              w="90%"
            >
              <Button
                mt={1}
                key={"mailbox-categories"}
                justifyContent={"flex-start"}
                w="full"
                bg={"transparent"}
                _hover={{ bg: "transparent" }}
                leftIcon={<FiTag fontSize="22px" style={{ marginRight: 12 }} />}
              >
                <Text variant="secondary">Categories</Text>
              </Button>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel p={0}>
            <Flex direction="column" flex={1} align="start">
              {Object.entries(CategoryFilters).map(([id, filterInfo]) => {
                return <CategoryButton id={id} filterInfo={filterInfo} />;
              })}
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  };

  const CategoryButton = ({
    id,
    filterInfo,
  }: {
    id: string;
    filterInfo: IFilterValue;
  }) => {
    const messageCount = useAppSelector((state) =>
      state.senders.filter((sender) => sender.categoryCounts[id])
    )
      ?.map((sender) => sender.categoryCounts[id])
      ?.reduce((a, b) => a + b, 0);
    return (
      <Button
        w="full"
        mt={1}
        ml={3}
        key={"category-" + id}
        justifyContent={"flex-start"}
        onClick={() => handleClick(id, filterInfo.type)}
        bg={
          labelFilter.labelId === id && location.pathname === "/"
            ? "primary.400"
            : "transparent"
        }
        leftIcon={
          <filterInfo.icon fontSize="22px" style={{ marginRight: 12 }} />
        }
      >
        <Flex>
          <Text variant="secondary">{id.split("_")[1]}</Text>
          <Text ml={2} variant="secondary">
            ({messageCount})
          </Text>
        </Flex>
      </Button>
    );
  };

  return (
    <Accordion mt={6} defaultIndex={0} allowToggle>
      <AccordionItem
        _hover={{
          bg: "transparent",
        }}
        _last={{
          borderBottomWidth: 0,
        }}
        borderBottomWidth={0}
        borderTopWidth={0}
      >
        <h2>
          <AccordionButton
            _hover={{
              bg: "transparent",
            }}
            p={0}
            display="flex"
            flex={1}
            justifyContent="space-between"
          >
            <Heading fontSize="md" textAlign="left">
              Mailbox
            </Heading>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel p={0}>
          <Flex direction="column" flex={1} align="start">
            {Object.entries(MailBoxFilters).map(([id, filterInfo]) => (
              <MailboxButton id={id} filterInfo={filterInfo} />
            ))}
            <CategorySection />
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

const MailboxButton = ({
  id,
  filterInfo,
}: {
  id: string;
  filterInfo: IFilterValue;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { labelFilter } = useFilters();
  const { onLabelPress } = useSearchFilters();
  const { isMessageViewOpen, focusedSender } = useMessageView();
  const { setSidenavExpanded } = useSidenavExpanded();
  const isMobile = window.innerWidth < 768;

  const handleClick = (id: string, filterType: EFilterTypes) => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    if (isMessageViewOpen && focusedSender) {
      // getMessages({
      //   email: focusedSender,
      //   defaultSelected: selectedSenders.includes(focusedSender),
      //   labelId: id,
      // });
    }
    onLabelPress({
      id,
      filterType,
    });
    if (isMobile) {
      setSidenavExpanded(false);
    }
  };

  const messageCount = useAppSelector((state) =>
    state.senders.filter((sender) => {
      if (id === EMailBoxFilters.LISTS) return sender.unsubscribeLink;
      return sender.categoryCounts[getCategoryKey(id)];
    })
  )
    ?.map((sender) => sender.categoryCounts[getCategoryKey(id)])
    ?.reduce((a, b) => a + b, 0);

  return (
    <Button
      w="full"
      mt={1}
      //ml={3}
      key={"category-" + id}
      justifyContent={"flex-start"}
      onClick={() => handleClick(id, filterInfo.type)}
      bg={
        labelFilter.labelId === id && location.pathname === "/"
          ? "primary.400"
          : "transparent"
      }
      leftIcon={<filterInfo.icon fontSize="22px" style={{ marginRight: 12 }} />}
    >
      <Flex>
        <Text variant="secondary">
          {id.toLowerCase().charAt(0).toUpperCase() + id.slice(1).toLowerCase()}
        </Text>
        <Text ml={2} variant="secondary">
          ({messageCount})
        </Text>
      </Flex>
    </Button>
  );
};
