import { Flex, ButtonGroup, Button } from "@chakra-ui/react";
import { useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { TbArrowsSort } from "react-icons/tb";
import { useSelection } from "../../hooks/store/useSelection";
import { useMessageView } from "../../context/MessageViewContext";
import SortModal from "../modals/sortModal";
import { useIsSelectionViewActive } from "../../context/SelectionViewContext";

export const scrollbarDisplayNone = {
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "&::-webkit-scrollbar-thumb": {
    height: "0px",
    width: "0px",
    display: "none",
  },
};
export const BottomFloatingToolbar = () => {
  const isMobile = window.innerWidth < 768;

  const { selectAll, selectAllMessagesFromSender, clearSelection } =
    useSelection();

  const [sortModalOpen, setSortModalOpen] = useState(false);
  const { isMessageViewOpen, focusedSender } = useMessageView();
  const { isSelectionViewActive, setIsSelectionViewActive } =
    useIsSelectionViewActive();

  const handleCancelSelection = () => {
    setIsSelectionViewActive(false);
    clearSelection();
  };

  const handleSelectAll = () => {
    if (focusedSender) {
      selectAllMessagesFromSender(focusedSender);
    } else selectAll();
  };

  if (isMobile)
    return (
      <Flex
        css={scrollbarDisplayNone}
        w="100vw"
        overflow="scroll"
        p={4}
        pb={4}
        pos="absolute"
        justify={
          isMessageViewOpen && isSelectionViewActive ? "flex-start" : "center"
        }
        bottom={-1}
        bgGradient="linear(to top, gray.900, transparent)"
      >
        <SortModal
          isOpen={sortModalOpen}
          onClose={() => setSortModalOpen(false)}
        />
        {isSelectionViewActive ? (
          <ButtonGroup spacing={2}>
            <BackButton />
            <Button onClick={handleSelectAll} variant="pill">
              Select All
            </Button>
            <Button onClick={clearSelection} variant="pill">
              Deselect
            </Button>
            <Button
              bg="gray.700"
              borderWidth="none"
              onClick={handleCancelSelection}
              variant="pill"
            >
              Cancel
            </Button>
          </ButtonGroup>
        ) : (
          <ButtonGroup spacing={2}>
            <BackButton />
            <Button
              onClick={() => setIsSelectionViewActive(!isSelectionViewActive)}
              variant="pill"
            >
              Select
            </Button>
            <SortButton onClick={() => setSortModalOpen(true)} />
            {/* <GroupButton /> */}
          </ButtonGroup>
        )}
      </Flex>
    );
  else return null;
};

const BackButton: React.FC = () => {
  const { isMessageViewOpen, setMessageViewOpen } = useMessageView();
  return (
    <Button
      variant="pill"
      border="none"
      bg="gray.700"
      px={3}
      pr={4}
      py={2}
      leftIcon={<BiChevronLeft fontSize={"20px"} style={{ marginRight: -8 }} />}
      onClick={() => setMessageViewOpen(false)}
      display={isMessageViewOpen ? "flex" : "none"}
    >
      Back
    </Button>
  );
};

const SortButton = ({ onClick }: { onClick: () => void }) => {
  const { isMessageViewOpen } = useMessageView();
  const { isSelectionViewActive } = useIsSelectionViewActive();
  return (
    <Button
      aria-label="Sort Options"
      variant="pill"
      border="none"
      bg="gray.700"
      px={3}
      pr={4}
      py={2}
      leftIcon={<TbArrowsSort fontSize={"20px"} />}
      onClick={onClick}
      display={!isSelectionViewActive && !isMessageViewOpen ? "flex" : "none"}
    >
      Sort
    </Button>
  );
};

// const GroupButton: React.FC = () => {
//   const { isMessageViewOpen } = useMessageView();
//   const { isSelectionViewActive } = useSelection();
//   return (
//     <Button
//       variant="pill"
//       border="none"
//       bg="gray.700"
//       px={3}
//       pr={4}
//       py={2}
//       leftIcon={<BiGroup fontSize={"20px"} />}
//       onClick={() => console.log("Group button clicked")}
//       display={!isSelectionViewActive && !isMessageViewOpen ? "flex" : "none"}
//     >
//       Group
//     </Button>
//   );
// };
