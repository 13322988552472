import {
  Button,
  Flex,
  GridItem,
  HStack,
  Heading,
  Progress,
  Skeleton,
  Stack,
  Text,
  Wrap,
} from "@chakra-ui/react";
import { BiChevronLeft } from "react-icons/bi";
import { useFetchOnScrollToEnd } from "../../hooks/common/useFetchOnScrollToEnd";
import { useMessageView } from "../../context/MessageViewContext";
import { useGetMessages } from "../../hooks/queries/useGetMessages";
import {
  useFilters,
  useMessages,
  useSelectedSenders,
} from "../../store/selectors";
import { MessageItem } from "./messageItem";
import { EFilterTypes, EMailBoxFilters } from "../../store/slices/searchSlice";
import { IGmailMessage } from "../../store/slices/messageSlice";
import { EmailLabel } from "../../lib/constants";
import { useMailboxLabels } from "../../hooks/queries/useMailboxLabels";

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export default function MessageView() {
  const { labels } = useMailboxLabels();
  const { labelFilter } = useFilters();
  const { focusedSender, setMessageViewOpen } = useMessageView();
  const selectedSenders = useSelectedSenders();
  const isFocusedSenderSelected = !!selectedSenders.find(
    (s) => s.email === focusedSender!
  );
  const messages = useMessages();
  const viewableMessages = messages.filter((message: IGmailMessage) => {
    const excludedLabelIds = [EMailBoxFilters.ALL, EMailBoxFilters.LISTS];
    if (
      message.from === focusedSender &&
      !excludedLabelIds.includes(labelFilter.labelId as any)
    ) {
      return message.labelIds?.includes(labelFilter.labelId as EmailLabel);
    }
    return message.from === focusedSender;
  });
  const { fetchNextPage, isFetchingNextPage, isLoading } = useGetMessages(
    focusedSender,
    isFocusedSenderSelected
  );
  useFetchOnScrollToEnd({
    containerId: "message-list",
    callback: () => !isFetchingNextPage && fetchNextPage(),
  });

  const handleBackClick = () => {
    setMessageViewOpen(false);
  };

  const showEmailLabel =
    labelFilter.labelId !== EFilterTypes.LabelFilter &&
    labelFilter.labelId !== EMailBoxFilters.ALL;

  return (
    <GridItem
      pos="relative"
      id="message-list"
      sx={{
        "&::-webkit-scrollbar": { width: "3px" },
        "&::-webkit-scrollbar-track": { background: "gray.700" },
        "&::-webkit-scrollbar-thumb": { background: "gray.400" },
      }}
      h="full"
      overflowY="scroll"
      px={2}
    >
      <Flex
        direction="column"
        pos="sticky"
        top={-1}
        bg={{ base: "gray.900", md: "gray.800" }}
        p={2}
        zIndex={1}
        align="flex-start"
        borderBottomColor="gray.700"
        borderBottomWidth={0.1}
      >
        <Button
          display={{ base: "none", md: "flex" }}
          onClick={handleBackClick}
          _hover={{ bg: "unset" }}
          color="gray.500"
          p={{ base: 0 }}
          variant="ghost"
          leftIcon={<BiChevronLeft fontWeight="bold" fontSize="24px" />}
        >
          BACK
        </Button>
        <Wrap maxW="full">
          <HStack mr={2}>
            <Heading
              display="flex"
              ml={{ base: 0 }}
              py={0}
              my={0}
              fontSize={{ base: "sm", md: "lg" }}
              color="gray.400"
            >
              From:&nbsp;
              <Text noOfLines={1} color="white">
                {capitalizeFirstLetter(focusedSender!) ?? ""}
              </Text>
            </Heading>
          </HStack>
          <Heading
            alignSelf={"flex-start"}
            display="flex"
            ml={{ base: 0 }}
            py={0}
            my={0}
            fontSize={{ base: "sm", md: "lg" }}
            color="gray.400"
          >
            {showEmailLabel && <span>IN: &nbsp;</span>}
            <Text noOfLines={1} color="white">
              {capitalizeFirstLetter(labelFilter.labelName) ?? ""}
            </Text>
          </Heading>
        </Wrap>
      </Flex>
      {isLoading && (
        <Stack flex={1}>
          <Skeleton startColor="gray.700" endColor="gray.800" h={40} />
          <Skeleton startColor="gray.700" endColor="gray.800" h={40} />
          <Skeleton startColor="gray.700" endColor="gray.800" h={40} />
          <Skeleton startColor="gray.700" endColor="gray.800" h={40} />
          <Skeleton startColor="gray.700" endColor="gray.800" h={40} />
        </Stack>
      )}
      {!isLoading &&
        viewableMessages.map((messageInfo) => (
          <MessageItem
            key={messageInfo.id}
            message={messageInfo}
            labels={labels}
          />
        ))}
      {isFetchingNextPage && (
        <Flex pos="absolute" bottom="20px" justify="center">
          <Progress
            size="xs"
            w="full"
            bg="transparent"
            ml="50%"
            isIndeterminate
          />
        </Flex>
      )}
    </GridItem>
  );
}
