import { Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { useFilters, useSelectedSenders, useSort } from "../../store/selectors";
import { useMessageView } from "../../context/MessageViewContext";
import SenderGrid from "./senderGrid";
import MessageView from "../messages/sidePeek";
import { BottomFloatingToolbar } from "../toolbar/toolbar";
import { useIsSelectionViewActive } from "../../context/SelectionViewContext";
import { useMemo } from "react";
import { store } from "../../store/store";
import { handleSort } from "../../utilities/helpers";
import { useAppSelector } from "../../store/provider";

export const DashboardBody = () => {
  const filters = useFilters();
  const { isMessageViewOpen } = useMessageView();
  const { isSelectionViewActive } = useIsSelectionViewActive();
  const isMobile = window.innerWidth < 768;
  const selectedSenders = useSelectedSenders();
  const sort = useSort();
  const completedAction = useAppSelector((state) => state.action);

  const gridColumns = isMobile
    ? "1fr"
    : isMessageViewOpen
    ? "380px auto"
    : "1fr";

  const containerHeight = {
    base:
      selectedSenders.length > 0
        ? "calc(100vh - 214px)"
        : "calc(100vh - 100px)",
    md: "full",
  };

  const senders = useMemo(() => {
    const labelId = filters.labelFilter.labelId;
    return handleSort({
      senders: store
        .getState()
        .senders.filter(
          (s) =>
            !s.hidden &&
            (s.categoryCounts[labelId] ?? s.categoryCounts.total) > 0
        ),
      type: store.getState().sortType,
      labelId: labelId,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, isSelectionViewActive, sort, completedAction]);

  return (
    <Grid
      pos="relative"
      templateColumns={gridColumns}
      h={containerHeight}
      overflow="hidden"
      mx={{
        base: 0,
        md: 6,
      }}
    >
      {senders?.length === 0 ? (
        <Flex direction="column" flex={1} align="center" mt={32}>
          <Flex>
            <Text color="primary.500" fontSize="80px" opacity={0.1}>
              0
            </Text>
          </Flex>
        </Flex>
      ) : (
        <>
          <GridItem id="grid-container">
            <SenderGrid senders={senders!} />
          </GridItem>
          {isMessageViewOpen && <MessageView />}
          <BottomFloatingToolbar />
        </>
      )}
    </Grid>
  );
};
