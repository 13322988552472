import {
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Text,
  Wrap,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { format } from "date-fns";
import SenderAvatar from "../ui/senderAvatar";
import { getEmailEnding } from "../../utilities/helpers";
import { DynamicText } from "./dynamicText";
import { useUndo } from "../../hooks/mutations/useUndo";
import { IClientHistoryDoc } from "@/functions/types";

export enum SemanticActionTypes {
  UNSUB = "unsubbed",
  DELETE = "deleted",
  LABEL = "labeled",
  ARCHIVE = "archived",
}

export const HistoryCard = ({
  id,
  emails,
  action,
  timestamp,
  messageIds,
}: IClientHistoryDoc) => {
  const getActionText = (
    action: IClientHistoryDoc["action"]
  ): SemanticActionTypes => {
    switch (action) {
      case "UNSUBSCRIBE":
        return SemanticActionTypes.UNSUB;
      case "TRASH" as any:
        return SemanticActionTypes.DELETE;
      case "LABEL" as any:
        return SemanticActionTypes.LABEL;
      case "ARCHIVE" as any:
        return SemanticActionTypes.ARCHIVE;
      default:
        return SemanticActionTypes.ARCHIVE;
    }
  };

  const { revertAction } = useUndo();
  const [showModal, setShowModal] = useState(false);
  const date = format(timestamp, "PPpp");
  const showViewAll = emails?.length > 3;

  const senderSlice = useMemo(() => {
    if (emails?.length > 3) {
      return emails?.slice(0, 3);
    }
    return emails;
  }, [emails]);

  const actionText = getActionText(action);

  return (
    <>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex direction="column">
              <Text fontSize="lg" variant="secondary">
                {date}
              </Text>
              <DynamicText
                action={actionText}
                numEditedSenders={emails?.length}
                numEditedMessages={messageIds.length}
              />
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Wrap mb={8} borderRadius="lg" overflow="hidden" w="full">
              {emails.map((email) => (
                <Tag p="1" pr="3" key={email}>
                  <SenderAvatar
                    small
                    senderName={email}
                    urlEnding={getEmailEnding(email)}
                  />
                  <TagLabel textOverflow="ellipsis">{email}</TagLabel>
                </Tag>
              ))}
            </Wrap>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex
        direction="column"
        rounded="lg"
        p={5}
        background="primary.700"
        boxShadow="md"
      >
        <Text fontSize="sm" variant="secondary" mb={2}>
          {date}
        </Text>

        <DynamicText
          action={actionText}
          numEditedSenders={emails?.length}
          numEditedMessages={messageIds.length}
        />
        <Wrap
          mb={showViewAll ? 0 : 8}
          borderRadius="lg"
          mt={2}
          overflow="hidden"
          w="full"
        >
          {senderSlice.map((email) => (
            <Tag key={email}>
              <SenderAvatar
                senderName={email}
                width="15px"
                height="15px"
                urlEnding={getEmailEnding(email)}
              />
              <TagLabel textOverflow="ellipsis">{email}</TagLabel>
            </Tag>
          ))}
        </Wrap>
        {showViewAll && (
          <Link
            onClick={() => setShowModal(true)}
            mb={4}
            mt={2}
            fontSize="sm"
            variant="ghost"
          >
            View all
          </Link>
        )}
        <Button
          isLoading={false}
          onClick={() => revertAction(id)}
          mt="auto"
          variant="solid"
        >
          Undo
        </Button>
      </Flex>
    </>
  );
};

// export const HistoryCard = (props: IClientHistoryDoc) => {
//   return null;
// };
