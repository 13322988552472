import { TRootState, useAppSelector } from "./provider";
import { createSelector } from "@reduxjs/toolkit";
import { EFilterTypes, EMailBoxFilters } from "./slices/searchSlice";
import { EmailLabel } from "../lib/constants";
import { ISender } from "./slices/senderSlice";
import { ISelectedSender } from "../lib/types";

const selectSenders = (state: TRootState) => state.senders;
const selectFilters = (state: TRootState) => state.filters;
const selectCurrentLabelId = (state: TRootState) =>
  state.filters.labelFilter.labelId;
const selectSort = (state: TRootState) => state.sortType;
const selectMessages = (state: TRootState) => state.messages;

const getCount = ({
  selectedMessageIds,
  sender,
}: {
  selectedMessageIds: string[];
  sender: ISender;
}) => {
  const currentLabel = sender?.selectedLabels[0] as unknown as string;
  if (currentLabel && sender) {
    if (
      currentLabel === EMailBoxFilters.ALL ||
      currentLabel === EFilterTypes.UnsubscribeLink
    )
      return sender.categoryCounts["total"];
    else return sender.categoryCounts[currentLabel];
  }
  return selectedMessageIds.length;
};

const filterSenders = (
  senders: TRootState["senders"],
  filters: TRootState["filters"]
) => {
  const labelFilter = filters.labelFilter.labelId;
  switch (labelFilter) {
    case EMailBoxFilters.ALL:
    case EMailBoxFilters.LISTS:
      return senders.filter((sender) => !sender.hidden);
    default:
      return senders.filter(
        (sender) => !sender.hidden && sender.categoryCounts[labelFilter]
      );
  }
};

export const useSenders = () =>
  useAppSelector(
    createSelector([selectSenders, selectFilters], (senders, filters) =>
      filterSenders(senders, filters)
    )
  );

export const useAllSenders = () => useAppSelector((state) => state.senders);

export const useSelectedSenders = () =>
  useAppSelector(
    createSelector(selectSenders, (senders) =>
      senders.filter((sender: ISender) => sender.selected)
    )
  );

export const useFilters = () => useAppSelector(selectFilters);

// return all messages minus those in the trash cateogry
export const useMessages = () =>
  useAppSelector(
    createSelector(selectMessages, (messages) =>
      messages.filter(
        (message) => !message.labelIds?.includes(EmailLabel.TRASH)
      )
    )
  );

export const useSelectedMessageCount = () =>
  useAppSelector(
    createSelector(
      [selectSenders, selectMessages, selectCurrentLabelId],
      (senders, messages, labelId) => {
        const selectedSenderCategoryCount = senders
          .filter((sender) => sender.selected)
          .reduce((total, sender) => {
            if (
              labelId === EMailBoxFilters.ALL ||
              labelId === EMailBoxFilters.LISTS
            ) {
              return total + sender.categoryCounts.total;
            } else {
              return total + sender.categoryCounts[sender.selectedLabels[0]];
            }
          }, 0);

        const selectedMessageCount = messages.filter(
          (message) =>
            message.selected &&
            !senders.find((s) => s.email === message.from)?.selected
        ).length;

        return selectedSenderCategoryCount + selectedMessageCount;
      }
    )
  );

export const useLabelFilter = () =>
  useAppSelector(
    createSelector(selectFilters, (filters) => filters.labelFilter)
  );

export const useIsAllSendersSelected = () =>
  useAppSelector(
    createSelector(selectSenders, (senders) =>
      senders.every((sender: { selected: any }) => sender.selected)
    )
  );

export const useSearch = () =>
  useAppSelector(createSelector(selectFilters, (filters) => filters.search));

export const useSort = () => useAppSelector(selectSort);

export const useSelectedMessages = () =>
  useAppSelector(
    createSelector(selectMessages, (messages) =>
      messages.filter((message) => message.selected)
    )
  );

export const useSendersWithMessagesSelected = () =>
  useAppSelector(
    createSelector(
      [selectSenders, selectMessages, selectCurrentLabelId],
      (senders, messages) => {
        return senders
          .filter((s) => {
            return s.selected || s.indeterminate;
          })
          .map((sender) => {
            // selected message ids should only be included in selection if messages are individually selected (indeterminate: true)
            const selectedMessageIds = messages
              .filter(
                (message) =>
                  message.from === sender.email &&
                  message.selected &&
                  sender.indeterminate
              )
              .map((m) => m.id);
            const count = getCount({
              selectedMessageIds,
              sender,
            });
            return {
              email: sender.email,
              messageIds: selectedMessageIds,
              unsubscribeLink: sender.unsubscribeLink,
              selectedLabels: sender.selectedLabels,
              count: count,
            } as ISelectedSender;
          });
      }
    )
  );
