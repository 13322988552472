import { useContext, useState, createContext } from "react";

const SelectionViewContext = createContext({
  isSelectionViewActive: false,
  setIsSelectionViewActive: (active: boolean) => {},
});

export const SelectionViewProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isSelectionViewActive, setIsSelectionViewActive] = useState(false);

  return (
    <SelectionViewContext.Provider
      value={{ isSelectionViewActive, setIsSelectionViewActive }}
    >
      {children}
    </SelectionViewContext.Provider>
  );
};

export const useIsSelectionViewActive = () => {
  const ctx = useContext(SelectionViewContext);
  if (ctx === undefined) {
    throw new Error(
      "useIsSelectionViewActive must be used within a SelectionViewProvider"
    );
  }
  return ctx;
};
