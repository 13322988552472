import {
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { HiMenuAlt3 } from "react-icons/hi";
import { useSidenavExpanded } from "../../context/SidenavContext";
import {
  useIsAllSendersSelected,
  useSelectedSenders,
} from "../../store/selectors";
import { useSearchFilters } from "../../hooks/store/useSearchFilters";
import { FiSearch, FiX } from "react-icons/fi";
import { useSelection } from "../../hooks/store/useSelection";

export const DashboardHeader = () => {
  const selectedSenders = useSelectedSenders();
  const { labelFilter, search, onSearch, clearSearch } = useSearchFilters();
  const { setSidenavExpanded } = useSidenavExpanded();
  const { clearSelection, selectAll } = useSelection();

  const allSendersSelected = useIsAllSendersSelected();
  const isMobile = window.innerWidth < 768;

  return (
    <Flex direction="column" w="full" px={{ base: 4, md: 6 }}>
      <Flex mb={2} justify="space-between" align="center">
        <Flex align="center" maxW="80vw">
          <IconButton
            display={{ base: "flex", md: "none" }}
            fontSize="3xl"
            onClick={() => setSidenavExpanded(true)}
            zIndex={100}
            bg="transparent"
            aria-label="Open navigation menu"
            icon={<HiMenuAlt3 />}
          />
          <Heading noOfLines={1} fontSize="3xl" m={0}>
            {labelFilter.labelName || labelFilter.labelId}
          </Heading>
        </Flex>
      </Flex>
      <Flex>
        <InputGroup mb={4} w={{ base: "full", md: "full" }}>
          <InputLeftElement
            // eslint-disable-next-line react/no-children-prop
            children={<FiSearch />}
          />
          <Input
            value={search}
            w={{ base: "full", lg: "full" }}
            onChange={(e) => onSearch(e.target.value)}
            variant="filled"
            placeholder={
              "Search multiple senders. ex: sender1, sender2, ..." +
                !isMobile && "Search multiple senders ex: s1, s2, ..."
            }
          />
          <InputRightElement
            // eslint-disable-next-line react/no-children-prop
            children={<FiX />}
            onClick={clearSearch}
            _hover={{ cursor: "pointer" }}
          />
        </InputGroup>

        {!isMobile && (
          <Flex>
            {selectedSenders.length > 0 && (
              <Button
                ml={2}
                onClick={clearSelection}
                variant={allSendersSelected ? "solid" : "outline"}
              >
                Deselect
              </Button>
            )}
            <Button
              ml={2}
              onClick={selectAll}
              variant={allSendersSelected ? "solid" : "outline"}
            >
              Select All
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
