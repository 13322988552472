import { useMessageView } from "../../context/MessageViewContext";
import { useAppDispatch } from "../../store/provider";
import {
  useAllSenders,
  useFilters,
  useMessages,
  useSelectedMessages,
  useSenders,
} from "../../store/selectors";
import {
  deselectMessages,
  selectMessages,
} from "../../store/slices/messageSlice";
import {
  deSelectSenders,
  selectSenders,
  setSenderIndeterminate,
} from "../../store/slices/senderSlice";
import { EFilterTypes, EMailBoxFilters } from "../../store/slices/searchSlice";

export const useSelection = () => {
  const dispatch = useAppDispatch();

  const { focusedSender } = useMessageView();

  const allSenders = useAllSenders();
  const senders = useSenders();
  const messages = useMessages();
  const selectedMessages = useSelectedMessages();

  const { labelFilter } = useFilters();

  const focusedSenderMessages = messages.filter(
    (message) => message.from === focusedSender
  );

  const clearSelection = () => {
    dispatch(deSelectSenders(allSenders.map((sender) => sender.email)));
    dispatch(deselectMessages(selectedMessages.map((message) => message.id)));
  };

  const selectAll = () => {
    const emails = senders.map((sender) => sender.email);
    console.log({ emails });
    dispatch(
      selectSenders({
        emails,
        currentLabel: labelFilter.labelId,
      })
    );
    dispatch(selectMessages(messages.map((message) => message.id)));
  };

  const selectAllMessagesFromSender = (email: string) => {
    dispatch(
      selectSenders({
        emails: [email],
        currentLabel: labelFilter.labelId,
      })
    );
    const senderMessages = messages.filter((message) => message.from === email);
    dispatch(selectMessages(senderMessages.map((message) => message.id)));
  };

  /**
   * Toggles the selection of a message.
   *
   * If the message is already selected:
   * - The message is de-selected.
   * - If the length of selected messages after de-selection is 0, the sender is de-selected entirely.
   * - If the length of selected messages after de-selection is greater than 0, the sender is set to indeterminate.
   *
   * If the message is not already selected:
   * - The message is selected.
   * - If all in-view messages are selected after selection is applied, the sender is set to selected.
   * - If some viewable messages are selected after selection is applied, the sender is set to indeterminate.
   *
   * @param id The ID of the message to toggle.
   */
  const toggleMessageSelection = (id: string) => {
    const isMessageAlreadySelected = selectedMessages.find(
      (message) => message.id === id
    );

    if (isMessageAlreadySelected) {
      dispatch(deselectMessages([id]));

      const selectedMessageCountAfterDeselect = selectedMessages.filter(
        (message) => message.id !== id && message.from === focusedSender
      ).length;

      if (selectedMessageCountAfterDeselect < 1)
        dispatch(deSelectSenders([focusedSender!]));
      else
        dispatch(
          setSenderIndeterminate({
            email: focusedSender!,
            indeterminate: true,
          })
        );
    } else {
      dispatch(selectMessages([id]));

      const selectableFocusedSenderMessageCount = focusedSenderMessages.filter(
        (m) =>
          m.labelIds?.includes(labelFilter.labelId) ||
          labelFilter.labelId === EMailBoxFilters.ALL ||
          labelFilter.labelId === EFilterTypes.UnsubscribeLink
      ).length;
      const selectedMessageCountAfterSelect =
        selectedMessages.filter((m) => m.from === focusedSender).length + 1;
      console.log({
        focusedSender,
        selectableFocusedSenderMessageCount,
        selectedMessageCountAfterSelect,
      });

      if (
        selectedMessageCountAfterSelect === selectableFocusedSenderMessageCount
      )
        dispatch(
          selectSenders({
            emails: [focusedSender!],
            currentLabel: labelFilter.labelId,
          })
        );
      else
        dispatch(
          setSenderIndeterminate({
            email: focusedSender!,
            indeterminate: true,
          })
        );
    }
  };

  return {
    selectAll,
    clearSelection,
    toggleMessageSelection,
    selectAllMessagesFromSender,
  };
};
