import { useQuery } from "@tanstack/react-query";
import { User } from "firebase/auth";
import { getLabels } from "../../api/api";
import { IClientUser } from "../../api/types";
import { EQueryKeys } from "../../lib/queryKeys";
import { useAuth } from "../../context/AuthContext";

export const useMailboxLabels = () => {
  const { user, oauthToken, appCheckToken } = useAuth();
  const authObject = {
    ...user,
    oauthToken,
    appCheckToken,
  };

  const { data: labels, isLoading: isLabelsLoading } = useQuery({
    queryKey: [EQueryKeys.Labels, (user as User).uid],
    queryFn: () => getLabels({ auth: authObject as IClientUser }),
    enabled: !!user && !!oauthToken && !!appCheckToken,
    refetchOnMount: true,
  });

  return { labels: labels ?? [], isLabelsLoading };
};
