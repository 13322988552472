import React, { useState, useEffect, useMemo, useRef, memo } from "react";
import { FixedSizeGrid, areEqual } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useSidenavExpanded } from "../../context/SidenavContext";
import { useMessageView } from "../../context/MessageViewContext";
import { SenderCard } from "../senderCard";
import { ISender } from "../../store/slices/senderSlice";

const SenderGrid = memo(({ senders }: { senders: ISender[] }) => {
  const sideNavOpen = useSidenavExpanded();
  const { isMessageViewOpen, focusedSender, lastFocusedSender } =
    useMessageView();

  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  });
  const gridRef = useRef<FixedSizeGrid>(null);

  const columnCount = useMemo(() => {
    const containerWidth = containerDimensions.width;
    if (!containerWidth || isMessageViewOpen) {
      return 1;
    } else if (containerWidth < 768) {
      return 1;
    } else if (containerWidth >= 768 && containerWidth < 1025) {
      return 2;
    } else {
      return 3;
    }
  }, [containerDimensions, isMessageViewOpen]);

  const updateDimensions = () => {
    const gridContainer = document.getElementById("grid-container");
    if (gridContainer) {
      const width = gridContainer.clientWidth;
      const height = gridContainer.clientHeight;
      setContainerDimensions({ width, height });
    }
  };

  // Handles resizing the grid when the sidenav is expanded
  // Also handles resizing the grid when the window is resized
  useEffect(() => {
    updateDimensions(); // Set initial dimensions
    setTimeout(() => {
      updateDimensions();
    }, 500);
    window?.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [sideNavOpen, isMessageViewOpen]);

  // Scrolls to the focused sender
  useEffect(() => {
    const email = focusedSender ?? lastFocusedSender;
    const index = senders.findIndex((sender) => sender.email === email);

    if (index !== -1 && gridRef.current) {
      const { columnCount } = gridRef.current.props;
      const rowIndex = Math.floor(index / columnCount);
      const columnIndex = index % columnCount;
      gridRef.current.scrollToItem({
        rowIndex,
        columnIndex,
        align: window.innerWidth < 768 ? "start" : "auto",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedSender, lastFocusedSender, isMessageViewOpen, gridRef.current]);

  const columnWidth = containerDimensions.width
    ? containerDimensions.width / columnCount
    : 0;
  const rowHeight = 76;
  const rowCount = Math.ceil(senders.length / columnCount);

  return (
    <AutoSizer>
      {({ width, height }: { width: number; height: number }) => {
        return (
          <FixedSizeGrid
            ref={gridRef}
            columnCount={columnCount}
            rowCount={rowCount}
            columnWidth={columnWidth}
            rowHeight={rowHeight}
            height={height ?? 0}
            width={width ?? 0}
            itemData={senders}
            style={{ overflowX: "hidden" }}
          >
            {({ columnIndex, rowIndex, style }) => {
              const sender = senders[rowIndex * columnCount + columnIndex];
              if (!sender) return null;
              return (
                <Cell
                  sender={senders[rowIndex * columnCount + columnIndex]}
                  key={`${rowIndex}-${columnIndex}`}
                  style={{ ...style, maxWidth: width }}
                />
              );
            }}
          </FixedSizeGrid>
        );
      }}
    </AutoSizer>
  );
});

const Cell = memo((props: { sender: ISender; style: React.CSSProperties }) => {
  const { sender, style } = props;
  return (
    <div style={{ ...style }}>
      <SenderCard senderFromStore={sender} />
    </div>
  );
}, areEqual);

export default SenderGrid;
